/* eslint-disable no-useless-escape */

export default {
  'USA': {
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington|(District of Columbia)",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
      "Others",
    ],
    hierarchy: {
      "State Supreme Court": "(Supreme Court)",
      "Intermediate Appellate Courts": "Appellate|(Supreme Judicial)|Appeal|(Commonwealth Court)|(Court of Review)|(Military Commission Review)|(Veterans Claims)|(Judicial Panel on Multidistrict Litigation)",
      "Trial Courts": "(Tax Court)|(Superior Court)|(Court of Chancery)|(Court on the Judiciary)|(Criminal Court)|(Court of Common Pleas)|(Family Court)|(District Court)|Bankruptcy|(Land Court)|(Surrogate's Court)|(Court of Claims)|(Civil Court)|(Court of International Trade)|(Federal Claims)|(Foreign Intelligence Surveillance Court)"
    },
    courts: {
      'State Courts': [
        // Supreme
        { 'name': 'Alabama Supreme Court', 'id': 'ala', states: ['Alabama'] },
        { 'name': 'Alaska Supreme Court', 'id': 'alaska', states: ['Alaska'] },
        { 'name': 'Arizona Supreme Court', 'id': 'ariz', states: ['Arizona'] },
        { 'name': 'Arkansas Supreme Court', 'id': 'ark', states: ['Arkansas'] },
        { 'name': 'California Supreme Court', 'id': 'cal', states: ['California'] },
        { 'name': 'Colorado Supreme Court', 'id': 'colo', states: ['Colorado'] },
        { 'name': 'Connecticut Supreme Court', 'id': 'conn', states: ['Connecticut'] },
        { 'name': 'Delaware Supreme Court', 'id': 'del', states: ['Delaware'] },
        { 'name': 'Florida Supreme Court', 'id': 'fla', states: ['Florida'] },
        { 'name': 'Georgia Supreme Court', 'id': 'ga', states: ['Georgia'] },
        { 'name': 'Hawaii Supreme Court', 'id': 'haw', states: ['Hawaii'] },
        { 'name': 'Idaho Supreme Court', 'id': 'idaho', states: ['Idaho'] },
        { 'name': 'Illinois Supreme Court', 'id': 'ill', states: ['Illinois'] },
        { 'name': 'Indiana Supreme Court', 'id': 'ind', states: ['Indiana'] },
        { 'name': 'Iowa Supreme Court', 'id': 'iowa', states: ['Iowa'] },
        { 'name': 'Kansas Supreme Court', 'id': 'kan', states: ['Kansas'] },
        { 'name': 'Kentucky Supreme Court', 'id': 'ky', states: ['Kentucky'] },
        { 'name': 'Louisiana Supreme Court', 'id': 'la', states: ['Louisiana'] },
        { 'name': 'Michigan Supreme Court', 'id': 'mich', states: ['Michigan'] },
        { 'name': 'Minnesota Supreme Court', 'id': 'minn', states: ['Minnesota'] },
        { 'name': 'Mississippi Supreme Court', 'id': 'miss', states: ['Mississippi'] },
        { 'name': 'Missouri Supreme Court', 'id': 'mo', states: ['Missouri'] },
        { 'name': 'Montana Supreme Court', 'id': 'mont', states: ['Montana'] },
        { 'name': 'Nebraska Supreme Court', 'id': 'neb', states: ['Nebraska'] },
        { 'name': 'Nevada Supreme Court', 'id': 'nev', states: ['Nevada'] },
        { 'name': 'New Hampshire Supreme Court', 'id': 'nh', states: ['New Hampshire'] },
        { 'name': 'New Jersey Supreme Court', 'id': 'nj', states: ['New Jersey'] },
        { 'name': 'New Mexico Supreme Court', 'id': 'nm', states: ['New Mexico'] },
        { 'name': 'New York Supreme Court', 'id': 'nysupct', states: ['New York'] },
        { 'name': 'North Carolina Supreme Court', 'id': 'nc', states: ['North Carolina'] },
        { 'name': 'North Dakota Supreme Court', 'id': 'nd', states: ['North Dakota'] },
        { 'name': 'Ohio Supreme Court', 'id': 'ohio', states: ['Ohio'] },
        { 'name': 'Oklahoma Supreme Court', 'id': 'okla', states: ['Oklahoma'] },
        { 'name': 'Oregon Supreme Court', 'id': 'or', states: ['Oregon'] },
        { 'name': 'Pennsylvania Supreme Court', 'id': 'pa', states: ['Pennsylvania'] },
        { 'name': 'Rhode Island Supreme Court', 'id': 'ri', states: ['Rhode Island'] },
        { 'name': 'South Carolina Supreme Court', 'id': 'sc', states: ['South Carolina'] },
        { 'name': 'South Dakota Supreme Court', 'id': 'sd', states: ['South Dakota'] },
        { 'name': 'Tennessee Supreme Court', 'id': 'tenn', states: ['Tennessee'] },
        { 'name': 'Texas Supreme Court', 'id': 'tex', states: ['Texas'] },
        { 'name': 'Utah Supreme Court', 'id': 'utah', states: ['Utah'] },
        { 'name': 'Vermont Supreme Court', 'id': 'vt', states: ['Vermont'] },
        { 'name': 'Virginia Supreme Court', 'id': 'va', states: ['Virginia'] },
        { 'name': 'Washington Supreme Court', 'id': 'wash', states: ['Washington'] },
        { 'name': 'West Virginia Supreme Court', 'id': 'wva', states: ['West Virginia'] },
        { 'name': 'Wisconsin Supreme Court', 'id': 'wis', states: ['Wisconsin'] },
        { 'name': 'Wyoming Supreme Court', 'id': 'wyo', states: ['Wyoming'] },

        // intermediate appellate
        { 'name': 'Alabama Court of Civil Appeals', 'id': 'alacivapp', states: ['Alabama'] },
        { 'name': 'Alabama Court of Criminal Appeals', 'id': 'alacrimapp', states: ['Alabama'] },
        { 'name': 'Alaska Court of Appeals', 'id': 'alaskactapp', states: ['Alaska'] },
        { 'name': 'Arizona Court of Appeals', 'id': 'arizctapp', states: ['Arizona'] },
        { 'name': 'Arkansas Court of Appeals', 'id': 'arkctapp', states: ['Arkansas'] },
        { 'name': 'California Court of Appeal', 'id': 'calctapp', states: ['California'] },
        { 'name': 'California Appellate Division of the Superior Court', 'id': 'calappdeptsuper', states: ['California'] },
        { 'name': 'Colorado Court of Appeals', 'id': 'coloctapp', states: ['Colorado'] },
        { 'name': 'Colorado Industrial Claim Appeals Office', 'id': 'coloworkcompcom', states: ['Colorado'] },
        { 'name': 'Connecticut Appellate Court', 'id': 'connappct', states: ['Connecticut'] },
        { 'name': 'Florida District Court of Appeal', 'id': 'fladistctapp', states: ['Florida'] },
        { 'name': 'Georgia Court of Appeals', 'id': 'gactapp', states: ['Georgia'] },
        { 'name': 'Hawaii Intermediate Court of Appeals', 'id': 'hawapp', states: ['Hawaii'] },
        { 'name': 'Idaho Court of Appeals', 'id': 'idahoctapp', states: ['Idaho'] },
        { 'name': 'Illinois Appellate Court', 'id': 'illappct', states: ['Illinois'] },
        { 'name': 'Indiana Court of Appeals', 'id': 'indctapp', states: ['Indiana'] },
        { 'name': 'Iowa Court of Appeals', 'id': 'iowactapp', states: ['Iowa'] },
        { 'name': 'Kansas Court of Appeals', 'id': 'kanctapp', states: ['Kansas'] },
        { 'name': 'Kentucky Court of Appeals', 'id': 'kyctapp', states: ['Kentucky'] },
        { 'name': 'Louisiana Court of Appeal', 'id': 'lactapp', states: ['Louisiana'] },
        { 'name': 'Maine Supreme Judicial Court', 'id': 'me', states: ['Maine'] },
        { 'name': 'Maryland Court of Appeals', 'id': 'md', states: ['Maryland'] },
        { 'name': 'Maryland Court of Special Appeals', 'id': 'mdctspecapp', states: ['Maryland'] },
        { 'name': 'Massachusetts Supreme Judicial Court', 'id': 'mass', states: ['Massachusetts'] },
        { 'name': 'Massachusetts Appeals Court', 'id': 'massappct', states: ['Massachusetts'] },
        { 'name': 'Michigan Court of Appeals', 'id': 'michctapp', states: ['Michigan'] },
        { 'name': 'Minnesota Court of Appeals', 'id': 'minnctapp', states: ['Minnesota'] },
        { 'name': 'Mississippi Court of Appeals', 'id': 'missctapp', states: ['Mississippi'] },
        { 'name': 'Missouri Court of Appeals', 'id': 'moctapp', states: ['Missouri'] },
        { 'name': 'Nebraska Court of Appeals', 'id': 'nebctapp', states: ['Nebraska'] },
        { 'name': 'New Jersey Superior Court Appellate Division', 'id': 'njsuperctappdiv', states: ['New Jersey'] },
        { 'name': 'New Mexico Court of Appeals', 'id': 'nmctapp', states: ['New Mexico'] },
        { 'name': 'New York Court of Appeals', 'id': 'ny', states: ['New York'] },
        { 'name': 'New York Appellate Division', 'id': 'nyappdiv', states: ['New York'] },
        { 'name': 'New York Appellate Terms', 'id': 'nyappterm', states: ['New York'] },
        { 'name': 'North Carolina Court of Appeals', 'id': 'ncctapp', states: ['North Carolina'] },
        { 'name': 'North Dakota Court of Appeals', 'id': 'ndctapp', states: ['North Dakota'] },
        { 'name': 'Ohio Court of Appeals', 'id': 'ohioctapp', states: ['Ohio'] },
        { 'name': 'Oklahoma Court of Civil Appeals', 'id': 'oklacivapp', states: ['Oklahoma'] },
        { 'name': 'Oklahoma Court of Criminal Appeals', 'id': 'oklacrimapp', states: ['Oklahoma'] },
        { 'name': 'Oregon Court of Appeals', 'id': 'orctapp', states: ['Oregon'] },
        { 'name': 'Pennsylvania Commonwealth Court', 'id': 'pacommwct', states: ['Pennsylvania'] },
        { 'name': 'South Carolina Court of Appeals', 'id': 'scctapp', states: ['South Carolina'] },
        { 'name': 'Tennessee Court of Appeals', 'id': 'tennctapp', states: ['Tennessee'] },
        { 'name': 'Tennessee Court of Criminal Appeals', 'id': 'tenncrimapp', states: ['Tennessee'] },
        { 'name': "Tennessee Workers' Comp. Appeals Board", 'id': 'tennworkcompapp', states: ['Tennessee'] },
        { 'name': 'Texas Court of Appeals', 'id': 'texapp', states: ['Texas'] },
        { 'name': 'Texas Court of Criminal Appeals', 'id': 'texcrimapp', states: ['Texas'] },
        { 'name': 'Texas Special Court of Review', 'id': 'texreview', states: ['Texas'] },
        { 'name': 'Utah Court of Appeals', 'id': 'utahctapp', states: ['Utah'] },
        { 'name': 'Virginia Court of Appeals', 'id': 'vactapp', states: ['Virginia'] },
        { 'name': 'Washington Court of Appeals', 'id': 'washctapp', states: ['Washington'] },
        { 'name': 'District of Columbia Court of Appeals', 'id': 'dc', states: ['Others'] },
        { 'name': 'Wisconsin Court of Appeals', 'id': 'wisctapp', states: ['Wisconsin'] },

        // trial
        { 'name': 'Arizona Tax Court', 'id': 'ariztaxct', states: ['Arizona'] },
        { 'name': 'Connecticut Superior Court', 'id': 'connsuperct', states: ['Connecticut'] },
        { 'name': 'Delaware Court of Chancery', 'id': 'delch', states: ['Delaware'] },
        { 'name': 'Delaware Court on the Judiciary', 'id': 'deljudct', states: ['Delaware'] },
        { 'name': 'Delaware Court of Common Pleas', 'id': 'delctcompl', states: ['Delaware'] },
        { 'name': 'Delaware Family Court', 'id': 'delfamct', states: ['Delaware'] },
        { 'name': 'Delaware Superior Court', 'id': 'delsuperct', states: ['Delaware'] },
        { 'name': 'Indiana Tax Court', 'id': 'indtc', states: ['Indiana'] },
        { 'name': 'Massachusetts District Court', 'id': 'massdistct', states: ['Massachusetts'] },
        { 'name': 'Massachusetts Land Court', 'id': 'masslandct', states: ['Massachusetts'] },
        { 'name': 'Massachusetts Superior Court', 'id': 'masssuperct', states: ['Massachusetts'] },
        { 'name': 'New Jersey Tax Court', 'id': 'njtaxct', states: ['New Jersey'] },
        { 'name': 'New York City Civil Court', 'id': 'nycivct', states: ['New York'] },
        { 'name': 'New York City Criminal Court', 'id': 'nycrimct', states: ['New York'] },
        { 'name': 'New York Family Court', 'id': 'nyfamct', states: ['New York'] },
        { 'name': "New York Surrogate's Court", 'id': 'nysurct', states: ['New York'] },
        { 'name': 'North Carolina Superior Court', 'id': 'ncsuperct', states: ['North Carolina'] },
        { 'name': 'Ohio Court of Claims', 'id': 'ohioctcl', states: ['Ohio'] },
        { 'name': 'Oklahoma Court on the Judiciary', 'id': 'oklacoj', states: ['Oklahoma'] },
        { 'name': 'Oregon Tax Court', 'id': 'ortc', states: ['Oregon'] },
        { 'name': 'Pennsylvania Superior Court', 'id': 'pasuperct', states: ['Pennsylvania'] },
        {'name': 'Rhode Island Superior Court', 'id': 'risuperct', states: ['Rhode Island'] },
        { 'name': 'Vermont Superior Court', 'id': 'vtsuperct', states: ['Vermont'] },

        // others
        { 'name': 'Arkansas Attorney General Reports', 'id': 'arkag', states: ['Arkansas'] },
        { 'name': "Arkansas Workers' Compensation Commission", 'id': 'arkworkcompcom', states: ['Arkansas'] },
        {'name': 'California Attorney General Reports', 'id': 'calag', states: ['California'] },
        { 'name': 'Colorado Attorney General Reports', 'id': 'coloag', states: ['Colorado'] },
        { 'name': 'Connecticut Compensation Review Board', 'id': 'connworkcompcom', states: ['Connecticut'] },
        { 'name': 'Florida Attorney General Reports', 'id': 'flaag', states: ['Florida'] },
        { 'name': 'Kansas Attorney General Reports', 'id': 'kanag', states: ['Kansas'] },
        { 'name': 'Louisiana Attorney General Reports', 'id': 'laag', states: ['Louisiana'] },
        {'name': 'Maryland Attorney General Reports', 'id': 'mdag', states: ['Maryland'] },
        { 'name': 'Minnesota Attorney General Reports', 'id': 'minnag', states: ['Minnesota'] },
        { 'name': 'Missouri Attorney General Reports', 'id': 'moag', states: ['Missouri'] },
        { 'name': 'Montana Attorney General Reports', 'id': 'montag', states: ['Montana'] },
        { 'name': 'Montana Tax Appeal Board', 'id': 'monttc', states: ['Montana'] },
        { 'name': 'Nebraska Attorney General Reports', 'id': 'nebag', states: ['Nebraska'] },
        { 'name': 'New York Attorney General Reports', 'id': 'nyag', states: ['New York'] },
        { 'name': 'North Carolina Industrial Commission', 'id': 'ncworkcompcom', states: ['North Carolina'] },
        { 'name': 'Oklahoma Attorney General Reports', 'id': 'oklaag', states: ['Oklahoma'] },
        { 'name': 'Oklahoma Judicial Ethics Advisory Panel', 'id': 'oklajeap', states: ['Oklahoma'] },
        { 'name': 'Pennsylvania Judicial Discipline', 'id': 'cjdpa', states: ['Pennsylvania'] },
        { 'name': "Tennessee Court of Workers' Compensation Claims", 'id': 'tennworkcompcl', states: ['Tennessee'] },
        { 'name': 'Texas Attorney General Reports', 'id': 'texag', states: ['Texas'] },
        { 'name': 'Texas Judicial Panel on Multidistrict Litigation', 'id': 'texjpml', states: ['Texas'] },
        { 'name': 'Washington Attorney General Reports', 'id': 'washag', states: ['Washington'] },
        { 'name': 'Wisconsin Attorney General Reports', 'id': 'wisag', states: ['Wisconsin'] },
      ],
      'Federal Courts': [
        // trial - district
        { 'name': 'Alabama Middle District Court', 'id': 'almd', states: ['Alabama'] },
        { 'name': 'Alabama North District Court', 'id': 'alnd', states: ['Alabama'] },
        { 'name': 'Alabama South District Court', 'id': 'alsd', states: ['Alabama'] },
        { 'name': 'Alaska District Court', 'id': 'akd', states: ['Alaska'] },
        { 'name': 'Arizona District Court', 'id': 'azd', states: ['Arizona'] },
        { 'name': 'Arkansas Eastern District Court', 'id': 'ared', states: ['Arkansas'] },
        { 'name': 'Arkansas West District Court', 'id': 'arwd', states: ['Arkansas'] },
        { 'name': 'California Central District Court', 'id': 'cacd', states: ['California'] },
        { 'name': 'California Eastern District Court', 'id': 'caed', states: ['California'] },
        { 'name': 'California North District Court', 'id': 'cand', states: ['California'] },
        { 'name': 'California South District Court', 'id': 'casd', states: ['California'] },
        { 'name': 'Colorado District Court', 'id': 'cod', states: ['Colorado'] },
        { 'name': 'Connecticut District Court', 'id': 'ctd', states: ['Connecticut'] },
        { 'name': 'Delaware District Court', 'id': 'ded', states: ['Delaware'] },
        { 'name': 'Florida Middle District Court', 'id': 'flmd', states: ['Florida'] },
        { 'name': 'Florida North District Court', 'id': 'flnd', states: ['Florida'] },
        { 'name': 'Florida South District Court', 'id': 'flsd', states: ['Florida'] },
        { 'name': 'Georgia Middle District Court', 'id': 'gamd', states: ['Georgia'] },
        { 'name': 'Georgia North District Court', 'id': 'gand', states: ['Georgia'] },
        { 'name': 'Georgia South District Court', 'id': 'gasd', states: ['Georgia'] },
        { 'name': 'Hawaii District Court', 'id': 'hid', states: ['Hawaii'] },
        { 'name': 'Idaho District Court', 'id': 'idd', states: ['Idaho'] },
        { 'name': 'Illinois Central District Court', 'id': 'ilcd', states: ['Illinois'] },
        { 'name': 'Illinois North District Court', 'id': 'ilnd', states: ['Illinois'] },
        { 'name': 'Illinois South District Court', 'id': 'ilsd', states: ['Illinois'] },
        { 'name': 'Indiana North District Court', 'id': 'innd', states: ['Indiana'] },
        { 'name': 'Indiana South District Court', 'id': 'insd', states: ['Indiana'] },
        { 'name': 'Iowa North District Court', 'id': 'iand', states: ['Iowa'] },
        { 'name': 'Iowa South District Court', 'id': 'iasd', states: ['Iowa'] },
        { 'name': 'Kansas District Court', 'id': 'ksd', states: ['Kansas'] },
        { 'name': 'Kentucky Eastern District Court', 'id': 'kyed', states: ['Kentucky'] },
        { 'name': 'Kentucky West District Court', 'id': 'kywd', states: ['Kentucky'] },
        { 'name': 'Louisiana Eastern District Court', 'id': 'laed', states: ['Louisiana'] },
        { 'name': 'Louisiana Middle District Court', 'id': 'lamd', states: ['Louisiana'] },
        { 'name': 'Louisiana West District Court', 'id': 'lawd', states: ['Louisiana'] },
        { 'name': 'Maine District Court', 'id': 'med', states: ['Maine'] },
        { 'name': 'Maryland District Court', 'id': 'mdd', states: ['Maryland'] },
        { 'name': 'Massachusetts District Court', 'id': 'mad', states: ['Massachusetts'] },
        { 'name': 'Michigan Eastern District Court', 'id': 'mied', states: ['Michigan'] },
        { 'name': 'Michigan West District Court', 'id': 'miwd', states: ['Michigan'] },
        { 'name': 'Minnesota District Court', 'id': 'mnd', states: ['Minnesota'] },
        { 'name': 'Mississippi North District Court', 'id': 'msnd', states: ['Mississippi'] },
        { 'name': 'Mississippi South District Court', 'id': 'mssd', states: ['Mississippi'] },
        { 'name': 'Missouri Eastern District Court', 'id': 'moed', states: ['Missouri'] },
        { 'name': 'Missouri West District Court', 'id': 'mowd', states: ['Missouri'] },
        { 'name': 'Montana District Court', 'id': 'mtd', states: ['Montana'] },
        { 'name': 'Nebraska District Court', 'id': 'ned', states: ['Nebraska'] },
        { 'name': 'Nevada District Court', 'id': 'nvd', states: ['Nevada'] },
        { 'name': 'New Hampshire District Court', 'id': 'nhd', states: ['New Hampshire'] },
        { 'name': 'New Jersey District Court', 'id': 'njd', states: ['New Jersey'] },
        { 'name': 'New Mexico District Court', 'id': 'nmd', states: ['New Mexico'] },
        { 'name': 'New York Eastern District Court', 'id': 'nyed', states: ['New York'] },
        { 'name': 'New York North District Court', 'id': 'nynd', states: ['New York'] },
        { 'name': 'New York South District Court', 'id': 'nysd', states: ['New York'] },
        { 'name': 'New York West District Court', 'id': 'nywd', states: ['New York'] },
        { 'name': 'North Carolina Eastern District Court', 'id': 'nced', states: ['North Carolina'] },
        { 'name': 'North Carolina Middle District Court', 'id': 'ncmd', states: ['North Carolina'] },
        { 'name': 'North Carolina West District Court', 'id': 'ncwd', states: ['North Carolina'] },
        { 'name': 'North Dakota District Court', 'id': 'ndd', states: ['North Dakota'] },
        { 'name': 'Ohio North District Court', 'id': 'ohnd', states: ['Ohio'] },
        { 'name': 'Ohio South District Court', 'id': 'ohsd', states: ['Ohio'] },
        { 'name': 'Oklahoma Eastern District Court', 'id': 'oked', states: ['Oklahoma'] },
        { 'name': 'Oklahoma North District Court', 'id': 'oknd', states: ['Oklahoma'] },
        { 'name': 'Oklahoma West District Court', 'id': 'okwd', states: ['Oklahoma'] },
        { 'name': 'Oregon District Court', 'id': 'ord', states: ['Oregon'] },
        { 'name': 'Pennsylvania Eastern District Court', 'id': 'paed', states: ['Pennsylvania'] },
        { 'name': 'Pennsylvania Middle District Court', 'id': 'pamd', states: ['Pennsylvania'] },
        { 'name': 'Pennsylvania West District Court', 'id': 'pawd', states: ['Pennsylvania'] },
        { 'name': 'Rhode Island District Court', 'id': 'rid', states: ['Rhode Island'] },
        { 'name': 'South Carolina District Court', 'id': 'scd', states: ['South Carolina'] },
        { 'name': 'South Dakota District Court', 'id': 'sdd', states: ['South Dakota'] },
        { 'name': 'Tennessee Eastern District Court', 'id': 'tned', states: ['Tennessee'] },
        { 'name': 'Tennessee Middle District Court', 'id': 'tnmd', states: ['Tennessee'] },
        { 'name': 'Tennessee West District Court', 'id': 'tnwd', states: ['Tennessee'] },
        { 'name': 'Texas Eastern District Court', 'id': 'txed', states: ['Texas'] },
        { 'name': 'Texas North District Court', 'id': 'txnd', states: ['Texas'] },
        { 'name': 'Texas South District Court', 'id': 'txsd', states: ['Texas'] },
        { 'name': 'Texas West District Court', 'id': 'txwd', states: ['Texas'] },
        { 'name': 'Utah District Court', 'id': 'utd', states: ['Utah'] },
        { 'name': 'Vermont District Court', 'id': 'vtd', states: ['Vermont'] },
        { 'name': 'Virginia Eastern District Court', 'id': 'vaed', states: ['Virginia'] },
        { 'name': 'Virginia West District Court', 'id': 'vawd', states: ['Virginia'] },
        { 'name': 'Washington Eastern District Court', 'id': 'waed', states: ['Washington'] },
        { 'name': 'Washington West District Court', 'id': 'wawd', states: ['Washington'] },
        { 'name': 'District of Columbia District Court', 'id': 'dcd', states: ['Others'] },
        { 'name': 'West Virginia North District Court', 'id': 'wvnd', states: ['West Virginia'] },
        { 'name': 'West Virginia South District Court', 'id': 'wvsd', states: ['West Virginia'] },
        { 'name': 'Wisconsin Eastern District Court', 'id': 'wied', states: ['Wisconsin'] },
        { 'name': 'Wisconsin West District Court', 'id': 'wiwd', states: ['Wisconsin'] },
        { 'name': 'Wyoming District Court', 'id': 'wyd', states: ['Wyoming'] },

        // trial - bankruptcy
        { 'name': 'Alabama Middle District Bankruptcy Court', 'id': 'almb', states: ['Alabama'] },
        { 'name': 'Alabama North District Bankruptcy Court', 'id': 'alnb', states: ['Alabama'] },
        { 'name': 'Alabama South District Bankruptcy Court', 'id': 'alsb', states: ['Alabama'] },
        { 'name': 'Alaska Bankruptcy Court', 'id': 'akb', states: ['Alaska'] },
        { 'name': 'Arizona Bankruptcy Court', 'id': 'arb', states: ['Arizona'] },
        { 'name': 'Arkansas Eastern District Bankruptcy Court', 'id': 'areb', states: ['Arkansas'] },
        { 'name': 'Arkansas West District Bankruptcy Court', 'id': 'arwb', states: ['Arkansas'] },
        { 'name': 'California Central District Bankruptcy Court', 'id': 'cacb', states: ['California'] },
        { 'name': 'California North District Bankruptcy Court', 'id': 'canb', states: ['California'] },
        { 'name': 'California South District Bankruptcy Court', 'id': 'casb', states: ['California'] },
        { 'name': 'California Eastern District Bankruptcy Court', 'id': 'caeb', states: ['California'] },
        { 'name': 'Colorado Bankruptcy Court', 'id': 'cob', states: ['Colorado'] },
        { 'name': 'Connecticut Bankruptcy Court', 'id': 'ctb', states: ['Connecticut'] },
        { 'name': 'Delaware Bankruptcy Court', 'id': 'deb', states: ['Delaware'] },
        { 'name': 'Florida Middle District Bankruptcy Court', 'id': 'flmb', states: ['Florida'] },
        { 'name': 'Florida North District Bankruptcy Court', 'id': 'flnb', states: ['Florida'] },
        { 'name': 'Florida South District Bankruptcy Court', 'id': 'flsb', states: ['Florida'] },
        { 'name': 'Georgia Middle District Bankruptcy Court', 'id': 'gamb', states: ['Georgia'] },
        { 'name': 'Georgia North District Bankruptcy Court', 'id': 'ganb', states: ['Georgia'] },
        { 'name': 'Georgia South District Bankruptcy Court', 'id': 'gasb', states: ['Georgia'] },
        { 'name': 'Hawaii Bankruptcy Court', 'id': 'hib', states: ['Hawaii'] },
        { 'name': 'Idaho Bankruptcy Court', 'id': 'idb', states: ['Idaho'] },
        { 'name': 'Illinois  Central District Bankruptcy Court', 'id': 'ilcb', states: ['Illinois'] },
        { 'name': 'Illinois North District Bankruptcy Court', 'id': 'ilnb', states: ['Illinois'] },
        { 'name': 'Illinois South District Bankruptcy Court', 'id': 'ilsb', states: ['Illinois'] },
        { 'name': 'Indiana North District Bankruptcy Court', 'id': 'innb', states: ['Indiana'] },
        { 'name': 'Indiana South District Bankruptcy Court', 'id': 'insb', states: ['Indiana'] },
        { 'name': 'Iowa North District Bankruptcy Court', 'id': 'ianb', states: ['Iowa'] },
        { 'name': 'Iowa South District Bankruptcy Court', 'id': 'iasb', states: ['Iowa'] },
        { 'name': 'Kansas Bankruptcy Court', 'id': 'ksb', states: ['Kansas'] },
        { 'name': 'Kentucky Eastern District Bankruptcy Court', 'id': 'kyeb', states: ['Kentucky'] },
        { 'name': 'Kentucky West District Bankruptcy Court', 'id': 'kywb', states: ['Kentucky'] },
        { 'name': 'Louisiana Eastern District Bankruptcy Court', 'id': 'laeb', states: ['Louisiana'] },
        { 'name': 'Louisiana Middle District Bankruptcy Court', 'id': 'lamb', states: ['Louisiana'] },
        { 'name': 'Louisiana West District Bankruptcy Court', 'id': 'lawb', states: ['Lousiana'] },
        { 'name': 'Maine Bankruptcy Court', 'id': 'bapme', states: ['Maine'] },
        { 'name': 'Maine Bankruptcy Court', 'id': 'meb', states: ['Maine'] },
        { 'name': 'Maryland Bankruptcy Court', 'id': 'mdb', states: ['Maryland'] },
        { 'name': 'Massachusetts District Bankruptcy Court', 'id': 'mab', states: ['Massachusetts'] },
        { 'name': 'Massachusetts Bankruptcy Court', 'id': 'bapma', states: ['Massachusetts'] },
        { 'name': 'Michigan Eastern District Bankruptcy Court', 'id': 'mieb', states: ['Michigan'] },
        { 'name': 'Michigan West District Bankruptcy Court', 'id': 'miwb', states: ['Michigan'] },
        { 'name': 'Minnesota Bankruptcy Court', 'id': 'mnb', states: ['Minnesota'] },
        { 'name': 'Mississippi North District Bankruptcy Court', 'id': 'msnb', states: ['Mississippi'] },
        { 'name': 'Mississippi South District Bankruptcy Court', 'id': 'mssb', states: ['Mississippi'] },
        { 'name': 'Missouri Eastern District Bankruptcy Court', 'id': 'moeb', states: ['Missouri'] },
        { 'name': 'Missouri West District Bankruptcy Court', 'id': 'mowb', states: ['Missouri'] },
        { 'name': 'Montana Bankruptcy Court', 'id': 'mtb', states: ['Montana'] },
        { 'name': 'Nebraska Bankruptcy Court', 'id': 'nebraskab', states: ['Nebraska'] },
        { 'name': 'Nevada Bankruptcy Court', 'id': 'nvb', states: ['Nevada'] },
        { 'name': 'New Hampshire Bankruptcy Court', 'id': 'nhb', states: ['New Hampshire'] },
        { 'name': 'New Jersey Bankruptcy Court', 'id': 'njb', states: ['New Jersey'] },
        { 'name': 'New Mexico Bankruptcy Court', 'id': 'nmb', states: ['New Mexico'] },
        { 'name': 'New York North District Bankruptcy Court', 'id': 'nynb', states: ['New York'] },
        { 'name': 'New York South District Bankruptcy Court', 'id': 'nysb', states: ['New York'] },
        { 'name': 'New York Eastern District Bankruptcy Court', 'id': 'nyeb', states: ['New York'] },
        { 'name': 'New York West District Bankruptcy Court', 'id': 'nywb', states: ['New York'] },
        { 'name': 'North Carolina Eastern District Bankruptcy Court', 'id': 'nceb', states: ['North Carolina'] },
        { 'name': 'North Carolina Middle District Bankruptcy Court', 'id': 'ncmb', states: ['North Carolina'] },
        { 'name': 'North Carolina West District Bankruptcy Court', 'id': 'ncwb', states: ['North Carolina'] },
        { 'name': 'North Dakota Bankruptcy Court', 'id': 'ndb', states: ['North Dakota'] },
        { 'name': 'Ohio North District Bankruptcy Court', 'id': 'ohnb', states: ['Ohio'] },
        { 'name': 'Ohio South District Bankruptcy Court', 'id': 'ohsb', states: ['Ohio'] },
        { 'name': 'Oklahoma Eastern District Bankruptcy Court', 'id': 'okeb', states: ['Oklahoma'] },
        { 'name': 'Oklahoma North District Bankruptcy Court', 'id': 'oknb', states: ['Oklahoma'] },
        { 'name': 'Oklahoma West District Bankruptcy Court', 'id': 'okwb', states: ['Oklahoma'] },
        { 'name': 'Oregon Bankruptcy Court', 'id': 'orb', states: ['Oregon'] },
        { 'name': 'Pennsylvania Eastern District Bankruptcy Court', 'id': 'paeb', states: ['Pennsylvania'] },
        { 'name': 'Pennsylvania Middle District Bankruptcy Court', 'id': 'pamb', states: ['Pennsylvania'] },
        { 'name': 'Pennsylvania West District Bankruptcy Court', 'id': 'pawb', states: ['Pennsylvania'] },
        { 'name': 'Rhode Island Bankruptcy Court', 'id': 'rib', states: ['Rhode Island'] },
        { 'name': 'South Carolina Bankruptcy Court', 'id': 'scb', states: ['South Carolina'] },
        { 'name': 'South Dakota Bankruptcy Court', 'id': 'sdb', states: ['South Dakota'] },
        { 'name': 'Tennessee Eastern District Bankruptcy Court', 'id': 'tneb', states: ['Tennessee'] },
        { 'name': 'Tennessee Middle District Bankruptcy Court', 'id': 'tnmb', states: ['Tennessee'] },
        { 'name': 'Tennessee West District Bankruptcy Court', 'id': 'tnwb', states: ['Tennessee'] },
        { 'name': 'Texas North District Bankruptcy Court', 'id': 'txnb', states: ['Texas'] },
        { 'name': 'Texas South District Bankruptcy Court', 'id': 'txsb', states: ['Texas'] },
        { 'name': 'Texas Eastern District Bankruptcy Court', 'id': 'txeb', states: ['Texas'] },
        { 'name': 'Texas West District Bankruptcy Court', 'id': 'txwb', states: ['Texas'] },
        { 'name': 'Utah Bankruptcy Court', 'id': 'utb', states: ['Utah'] },
        { 'name': 'Vermont Bankruptcy Court', 'id': 'vtb', states: ['Vermont'] },
        { 'name': 'Virginia Eastern District Bankruptcy Court', 'id': 'vaeb', states: ['Virginia'] },
        { 'name': 'Virginia West District Bankruptcy Court', 'id': 'vawb', states: ['Virginia'] },
        { 'name': 'Washington Eastern District Bankruptcy Court', 'id': 'waeb', states: ['Washington'] },
        { 'name': 'Washington West District Bankruptcy Court', 'id': 'wawb', states: ['Washington'] },
        { 'name': 'District of Columbia Bankruptcy Court', 'id': 'dcb', states: ['Others'] },
        { 'name': 'West Virginia North District Bankruptcy Court', 'id': 'wvnb', states: ['West Virginia'] },
        { 'name': 'West Virginia South District Bankruptcy Court', 'id': 'wvsb', states: ['West Virginia'] },
        { 'name': 'Wisconsin Eastern District Bankruptcy Court', 'id': 'wieb', states: ['Wisconsin'] },
        { 'name': 'Wisconsin West District Bankruptcy Court', 'id': 'wiwb', states: ['Wisconsin'] },
        { 'name': 'Wyoming Bankruptcy Court', 'id': 'wyb', states: ['Wyoming'] },
      ],
      'Others': [
        { 'name': 'Federal Supreme Court', 'id': 'scotus', states: ['Others'] },
        { 'name': 'Air Force Court of Criminal Appeals', 'id': 'afcca', states: ['Others'] },
        { 'name': 'Armed Services Board of Contract Appeals', 'id': 'asbca', states: ['Others'] },
        { 'name': 'Army Court of Criminal Appeals', 'id': 'acca', states: ['Others'] },
        { 'name': 'Board of Immigration Appeals', 'id': 'bia', states: ['Others'] },
        { 'name': "Board of Veterans' Appeals", 'id': 'bva', states: ['Others'] },
        { 'name': 'Court of Appeals for the Armed Forces', 'id': 'armfor', states: ['Others'] },
        { 'name': 'Navy-Marine Corps Court of Criminal Appeals', 'id': 'nmcca', states: ['Others'] },
        { 'name': 'Court of International Trade', 'id': 'cit', states: ['Others'] },
        { 'name': 'Federal Claims', 'id': 'uscfc', states: ['Others'] },
        { 'name': 'Attorneys General', 'id': 'ag', states: ['Others'] },
        { 'name': 'Office of Legal Counsel', 'id': 'olc', states: ['Others'] },

        { 'name': 'Foreign Intelligence Surveillance Court', 'id': 'fisc', states: ['Others'] },
        { 'name': 'Foreign Intelligence Surveillance Court of Review', 'id': 'fiscr', states: ['Others'] },
        { 'name': 'Judicial Panel on Multidistrict Litigation', 'id': 'jpml', states: ['Others'] },
        { 'name': 'Merit Systems Protection Board', 'id': 'mspb', states: ['Others'] },
        { 'name': 'Military Commission Review', 'id': 'mc', states: ['Others'] },

        { 'name': 'Guam District Court', 'id': 'gud', states: ['Others'] },
        { 'name': 'Guam Bankruptcy Courts', 'id': 'gub', states: ['Others'] },
        { 'name': 'Northern Mariana Islands District Court', 'id': 'nmid', states: ['Others'] },
        { 'name': 'Northern Mariana Islands Bankruptcy Court', 'id': 'nmib', states: ['Others'] },
        { 'name': 'Puerto Rico District Court', 'id': 'prd', states: ['Others'] },
        { 'name': 'Puerto Rico Bankruptcy Courts', 'id': 'prb', states: ['Others'] },
        { 'name': 'Virgin Islands District Court', 'id': 'vid', states: ['Others'] },
        { 'name': 'Virgin Islands Bankruptcy Courts', 'id': 'vib', states: ['Others'] },
        
        { 'name': '1st Circuit Bankruptcy Court', 'id': 'bap1', states: ['Maine, Massachusetts, New Hampshire, Rhode Island'] },
        { 'name': '2nd Circuit Bankruptcy Court', 'id': 'bap2', states: ['Connecticut', 'New York', 'Vermont'] },
        { 'name': '6th Circuit Bankruptcy Court', 'id': 'bap6', states: ['Kentucky', 'Michigan', 'Ohio', 'Tennessee'] },
        { 'name': '8th Circuit Bankruptcy Court', 'id': 'bap8', states: ['Arkansas', 'Iowa', 'Minnesota', 'Missouri', 'Nebraska', 'North Dakota', 'South Dakota'] },
        { 'name': '9th Circuit Bankruptcy Court', 'id': 'bap9', states: ['Alaska', 'Arizona', 'California', 'Hawaii', 'Idaho', 'Montana', 'Nevada', 'Oregon', 'Washington'] },
        { 'name': '10th Circuit Bankruptcy Court', 'id': 'bap10', states: ['Colorado', 'Kansas', 'New Mexico', 'Oklahoma', 'Utah', 'Wyoming'] },

        { 'name': 'Federal Circuit Court of Appeals', 'id': 'cafc', states: ['ALL'] },
        { 'name': 'District of Columbia Circuit Court of Appeals', 'id': 'cadc' },
        { 'name': '1st Circuit Court of Appeals', 'id': 'ca1', states: ['Maine, Massachusetts, New Hampshire, Rhode Island'] },
        { 'name': '2nd Circuit Court of Appeals', 'id': 'ca2', states: ['Connecticut', 'New York', 'Vermont'] },
        { 'name': '3rd Circuit Court of Appeals', 'id': 'ca3', states: ['Delaware', 'New Jersey', 'Pennsylvania'] },
        { 'name': '4th Circuit Court of Appeals', 'id': 'ca4', states: ['Maryland', 'North Carolina', 'South Carolina', 'Virginia', 'West Virginia'] },
        { 'name': '5th Circuit Court of Appeals', 'id': 'ca5', states: ['Louisiana', 'Mississippi', 'Texas'] },
        { 'name': '6th Circuit Court of Appeals', 'id': 'ca6', states: ['Kentucky', 'Michigan', 'Ohio', 'Tennessee'] },
        { 'name': '7th Circuit Court of Appeals', 'id': 'ca7', states: ['Illinois', 'Indiana', 'Wisconsin'] },
        { 'name': '8th Circuit Court of Appeals', 'id': 'ca8', states: ['Arkansas', 'Iowa', 'Minnesota', 'Missouri', 'Nebraska', 'North Dakota', 'South Dakota'] },
        { 'name': '9th Circuit Court of Appeals', 'id': 'ca9', states: ['Alaska', 'Arizona', 'California', 'Hawaii', 'Idaho', 'Montana', 'Nevada', 'Oregon', 'Washington'] },
        { 'name': '10th Circuit Court of Appeals', 'id': 'ca10', states: ['Colorado', 'Kansas', 'New Mexico', 'Oklahoma', 'Utah', 'Wyoming'] },
        { 'name': '11th Circuit Court of Appeals', 'id': 'ca11', states: ['Alabama', 'Florida', 'Georgia'] },

        { 'name': 'U.S. Judicial Conference Committee', 'id': 'usjc', states: ['Others'] },
        { 'name': 'U.S. Tax Court', 'id': 'tax', states: ['Others'] },
        { 'name': 'Veterans Claims', 'id': 'cavc', states: ['Others'] },
      ],
    },
  },

  'UK': {
    states: [
      "England and Wales|(\(IPEC\))|(Care Standards Tribunal)|(Competition Appeals Tribunal)|(Employment Appeal Tribunal)|(Financial Services and Markets Tribunals)|(Information Tribunal)|(Lands Tribunal)|(VAT)|(Administrative Court)|(Exchequer Court)|(Senior Court Costs Office)|(Technology and Construction Court)",
      "Northern Ireland",
      "Scotland|Scottish",
      "Others"
    ],
    hierarchy: {
      "Supreme Court": "(Supreme Court)|(European Union)",
      "Court of Appeals": "(House of Lords)|(Court of Appeal)|(Appeal Court)|(Court of Session)|(Court of Criminal Appeal)",
      "High Courts": "(High Court)|(Commercial Court)|(Family Court)|(Patents Court)|(Queen's Bench Division)|(King's Bench Division)|(Administrative Court)|(Master's)",
      "Crown Courts": "(Crown Court)",
      "County Courts": "(County Court)|(\(IPEC\))",
      "Magistrates' Courts": "(Magistrates' Court)",
      "Specialized Courts": "Tribunal|(Court of Protection)|(Technology and Construction Court)"
    },
    courts: {
      "United Kingdom Jurisdiction": [
        // supreme
        { 'name': 'Supreme Court of Ireland Decisions', 'id': 'ie/cases/IESC', states: ['Northern Ireland'] },

        // appellate
        { 'name': 'Court of Appeal in England and Wales (Civil Division) Decisions', 'id': 'ew/cases/EWCA/Civ', states: ['England and Wales'] },
        { 'name': 'Court of Appeal in England and Wales (Criminal Division) Decisions', 'id': 'ew/cases/EWCA/Crim', states: ['England and Wales'] },
        { 'name': 'Court of Appeal in England and Wales Decisions', 'id': 'ew/cases/EWCA', states: ['England and Wales'] },
        { 'name': 'Court of Appeal in Northern Ireland Decisions', 'id': 'nie/cases/NICA', states: ['Northern Ireland'] },
        { 'name': 'Scottish Sheriff Appeal Courts (Civil Division) Decisions', 'id': 'scot/cases/ScotSAC/Civ', states: ['Scotland'] },
        { 'name': 'Scottish Sheriff Appeal Courts (Criminal Division) Decisions', 'id': 'scot/cases/ScotSAC/Crim', states: ['Scotland'] },
        { 'name': 'Scottish Court of Session Decisions', 'id': 'scot/cases/ScotCS', states: ['Scotland'] },

        // high court
        { 'name': 'Admiralty Division of the High Court of Justice in England and Wales', 'id': 'ew/cases/EWHC/Admlty', states: ['England and Wales'] },
        { 'name': 'Chancery Division of the High Court of Justice in England and Wales', 'id': 'ew/cases/EWHC/Ch', states: ['England and Wales'] },
        { 'name': 'Commercial Court Decisions', 'id': 'ew/cases/EWHC/Comm', states: ['England and Wales'] },
        { 'name': 'England and Wales Family Court', 'id': 'ew/cases/EWFC', states: ['England and Wales'] },
        { 'name': 'England and Wales Family Court (High Court Judges)', 'id': 'ew/cases/EWFC/HCJ', states: ['England and Wales'] },
        { 'name': 'England and Wales Family Court (Other Judges)', 'id': 'ew/cases/EWHC/OJ', states: ['England and Wales'] },
        { 'name': 'High Court of England and Wales', 'id': 'ew/cases/EWHC', states: ['England and Wales'] },
        { 'name': 'Family Division of the High Court in England and Wales Decisions', 'id': 'ew/cases/EWHC/Fam', states: ['England and Wales'] },
        { 'name': 'England and Wales Patents Court Decisions', 'id': 'ew/cases/EWHC/Patents', states: ['England and Wales'] },
        { 'name': "England and Wales Queen's Bench Division Decisions", 'id': 'ew/cases/EWHC/QB', states: ['England and Wales'] },
        { 'name': "England and Wales King's Bench Division Decisions", 'id': 'ew/cases/EWHC/KB', states: ['England and Wales'] },
        { 'name': 'Administrative Court Decisions', 'id': 'ew/cases/EWHC/Admin', states: ['England and Wales'] },
        { 'name': 'Mercantile Court of the High Court in England and Wales Decisions', 'id': 'ew/cases/EWHC/Mercantile', states: ['England and Wales'] },

        { 'name': 'Chancery Division of the High Court of Justice in Northern Ireland', 'id': 'nie/cases/NIHC/Ch', states: ['Northern Ireland'] },
        { 'name': 'Family Division of the High Court in Northern Ireland Decisions', 'id': 'nie/cases/NIHC/Fam', states: ['Northern Ireland'] },
        { 'name': 'High Court of Northern Ireland Decisions', 'id': 'nie/cases/NIHC', states: ['Northern Ireland'] },
        { 'name': "Northern Ireland Queen's Bench Division Decisions", 'id': 'nie/cases/NIHC/QB', states: ['Northern Ireland'] },
        { 'name': "Northern Ireland Master's Decisions", 'id': 'nie/cases/NIHC/Master', states: ['Northern Ireland'] },

        { 'name': 'Scottish High Court of Justiciary Decisons', 'id': 'scot/cases/ScotHC', states: ['Scotland'] },

        // crown court
        { 'name': 'Crown Court for Northern Ireland Decisions', 'id': 'nie/cases/NICC', states: ['Northern Ireland'] },

        // county court
        { 'name': 'England and Wales County Court (Family)', 'id': 'ew/cases/EWCC/Fam', states: ['England and Wales'] },
        { 'name': 'Patents County Court/Intellectual Property Enterprise Court(IPEC)', 'id': 'ew/cases/EWPCC', states: ['England and Wales'] },

        // magistrates court
        { 'name': "England and Wales Magistrates' Court (Family)", 'id': 'ew/cases/EWMC/FPC', states: ['England and Wales'] },

        // specialized court
        { 'name': 'Care Standards Tribunal', 'id': 'ew/cases/EWCST', states: ['England and Wales'] },
        { 'name': 'Competition Appeals Tribunal', 'id': 'uk/cases/CAT', states: ['Others'] },
        { 'name': 'Employment Appeal Tribunal', 'id': 'uk/cases/UKEAT', states: ['Others'] },
        { 'name': 'Financial Services and Markets Tribunals Decisions', 'id': 'uk/cases/UKFSM', states: ['Others'] },
        { 'name': 'Information Tribunal and the National Security Appeals Panel', 'id': 'uk/cases/UKIT', states: ['Others'] },
        { 'name': 'Lands Tribunal', 'id': 'ew/cases/EWLands', states: ['England and Wales'] },
        { 'name': 'VAT & Duties Tribunals Decisions', 'id': 'uk/cases/UKVAT', states: ['Others'] },
        { 'name': 'England and Wales Court of Protection', 'id': 'ew/cases/EWCOP', states: ['England and Wales'] },
        { 'name': 'Technology and Construction Court Decisions', 'id': 'ew/cases/EWHC/TCC', states: ['England and Wales'] },
        
        { 'name': 'Industrial Tribunals Northern Ireland Decisions', 'id': 'nie/cases/NIIT', states: ['Northern Ireland'] },
        { 'name': 'Fair Employment Tribunal Northern Ireland Decisions', 'id': 'nie/cases/NIFET', states: ['Northern Ireland'] },
        { 'name': "Northern Ireland Social Security and Child Support Commissioners' Decisions", 'id': 'nie/cases/NISSCSC', states: ['Northern Ireland'] },

        // others
        { 'name': 'Exchequer Court Decisions', 'id': 'ew/cases/EWHC/Exch', states: ['England and Wales'] },
        { 'name': 'Senior Court Costs Office Decisions', 'id': 'ew/cases/EWHC/Costs', states: ['England and Wales'] },
        { 'name': "England and Wales Social Security and Child Support Commissioners' Decisions", 'id': 'uk/cases/UKSSCSC', states: ['Others'] },
        
        { 'name': 'Scotland Case Law', 'id': 'scot/cases', states: ['Scotland'] },
        { 'name': 'Scottish Information Commissioner Decisions', 'id': 'scot/cases/ScotIC', states: ['Scotland'] },
        { 'name': 'Scottish Sheriff Court Decisions', 'id': 'scot/cases/ScotSC', states: ['Scotland'] },

      ],
      "Others": [
        { 'name': 'Supreme Court Decisions', 'id': 'uk/cases/UKSC', states: ['Others'] },
        { 'name': 'St Helena Supreme Court', 'id': 'sh/cases/SHSC', states: ['Others'] },
        { 'name': 'Court of Justice of the European Union (including the General Court)', 'id': 'eu/cases/EUECJ', states: ['Others'] },
        { 'name': 'House of Lords Decisions', 'id': 'uk/cases/UKHL', states: ['Others'] },
        { 'name': 'European Court of Human Rights', 'id': 'eu/cases/ECHR', states: ['Others'] },
        { 'name': 'Europe Case Law', 'id': 'eu/cases', states: ['Others'] },

        { 'name': 'Court of Appeal in Ireland', 'id': 'ie/cases/IECA', states: ['Others'] },
        { 'name': 'Court of Criminal Appeal in Ireland', 'id': 'ie/cases/IECCA', states: ['Others'] },
        { 'name': 'High Court of Ireland Decisions', 'id': 'ie/cases/IEHC', states: ['Others'] },
        { 'name': 'Ireland Case Law', 'id': 'ie/cases', states: ['Others'] },
        { 'name': 'Irish Competition Authority Decisions', 'id': 'ie/cases/IECompA', states: ['Others'] },
        { 'name': 'Irish Data Protection Commission Case Studies', 'id': 'ie/cases/IEDPC', states: ['Others'] },
        { 'name': "Irish Information Commissioner's Decisions", 'id': 'ie/cases/IEIC', states: ['Others'] },

        { 'name': 'Jersey Law Reports', 'id': 'je/cases/JLR', states: ['Others'] },
        { 'name': 'Jersey Unreported Judgments', 'id': 'je/cases/UR', states: ['Others'] },
        { 'name': 'Jersey Case Law', 'id': 'je/cases', states: ['Others'] },
        // { 'name': 'Jersey: All Case Law', 'id': 'sh/cases' }, // [!!]

        { 'name': 'Nominet UK Dispute Resolution Service', 'id': 'uk/cases/DRS', states: ['Others'] },
        { 'name': 'Privy Council Decisions', 'id': 'uk/cases/UKPC', states: ['Others'] },
        { 'name': 'Special Commissioners of Income Tax Decisions', 'id': 'uk/cases/UKSPC', states: ['Others'] },
        { 'name': 'Special Immigrations Appeals Commission', 'id': 'uk/cases/SIAC', states: ['Others'] },
      ],
    },
  },

  'AUS': {
    states: [
      "Australian Capital Territory|(ACT )",
      "New South Wales|(\(NSW\))",
      "Victoria|Victorian",
      "Queensland",
      "South Australia|(South Australian)",
      "Western Australia|(Western Australian)",
      "Tasmania|Tasmanian",
      "Others"
    ],
    hierarchy: {
      "High Court": "(High Court)",
      "Federal Court": "(Federal Court)",
      "Family Court": "(Family Court)",
      "Federal Circuit Court": "(Federal Circuit Court)",
      "Intermediate Courts": "(Supreme Court)|(Superior Court)|(District Court)|(County Court)",
      "Magistrates' Courts": "(Magistrates Court)|(Magistrate)|(Local Court)",
    },
    courts: {
      'Australia Jurisdiction': [
        //family
        { 'name': 'Family Court of Western Australia  Magistrates Decisions 2008', 'id': 'wa/FCWAM', states: ['Western Australia'] },
        { 'name': 'Family Court of Western Australia 2004', 'id': 'wa/FCWA', states: ['Western Australia'] },

        // intermediate
        { 'name': "Knox's New South Wales Supreme Court Reports 18731877", 'id': 'nsw/NSWKnoxRp', states: ['New South Wales'] },
        { 'name': "Legge's Supreme Court Cases (NSW) 18301863", 'id': 'nsw/NSWLeggeSC', states: ['New South Wales'] },
        { 'name': 'Superior Courts of New South Wales 17881899', 'id': 'nsw/NSWSupC', states: ['New South Wales'] },
        { 'name': 'Superior Courts of Tasmania 18241843', 'id': 'tas/TASSupC', states: ['Tasmania'] },
        { 'name': 'Superior Courts of Western Australia 18401849', 'id': 'wa/WASupC', states: ['Western Australia'] },
        { 'name': 'Supreme Court of New South Wales  Court of Appeal 1988', 'id': 'nsw/NSWCA', states: ['New South Wales'] },
        { 'name': 'Supreme Court of New South Wales  Court of Criminal Appeal 1998', 'id': 'nsw/NSWCCA', states: ['New South Wales'] },
        { 'name': 'Supreme Court of New South Wales 1993', 'id': 'nsw/NSWSC', states: ['New South Wales'] },
        { 'name': 'Supreme Court of Norfolk Island 1984', 'id': 'nf/NFS', states: ['Others'] },
        { 'name': 'Supreme Court of Queensland  Court of Appeal 1992 ', 'id': 'qld/QCA', states: ['Queensland'] },
        { 'name': 'Supreme Court of Queensland 1994 ', 'id': 'qld/QSC', states: ['Queensland'] },
        { 'name': 'Supreme Court of South Australia  Court of Appeal 2021', 'id': 'sa/SASCA', states: ['South Australia'] },
        { 'name': 'Supreme Court of South Australia  Full Court 20102021', 'id': 'sa/SASCFC', states: ['South Australia'] },
        { 'name': 'Supreme Court of South Australia 1977 ', 'id': 'sa/SASC', states: ['South Australia'] },
        { 'name': 'Supreme Court of Tasmania  Court of Criminal Appeal 2010', 'id': 'tas/TASCCA', states: ['Tasmania'] },
        { 'name': 'Supreme Court of Tasmania  Full Court 2010', 'id': 'tas/TASFC', states: ['Tasmania'] },
        { 'name': 'Supreme Court of Tasmania 1985', 'id': 'tas/TASSC', states: ['Tasmania'] },
        { 'name': 'Supreme Court of Victoria  Court of Appeal 1998', 'id': 'vic/VSCA', states: ['Victoria'] },
        { 'name': 'Supreme Court of Victoria (Unreported Judgments) 19531998', 'id': 'vic/VicSC', states: ['Victoria'] },
        { 'name': 'Supreme Court of Victoria 1994 ', 'id': 'vic/VSC', states: ['Victoria'] },
        { 'name': 'Supreme Court of Western Australia  Court of Appeal 1998', 'id': 'wa/WASCA', states: ['Western Australia'] },
        { 'name': 'Supreme Court of Western Australia 1964', 'id': 'wa/WASC', states: ['Western Australia'] },
        { 'name': 'Supreme Court of the Australian Capital Territory  Court of Appeal 2002', 'id': 'act/ACTCA', states: ['Australian Capital Territory'] },
        { 'name': 'Supreme Court of the Australian Capital Territory (Full Court) 2014', 'id': 'act/ACTSCFC', states: ['Australian Capital Territory'] },
        { 'name': 'Supreme Court of the Australian Capital Territory 1986', 'id': 'act/ACTSC', states: ['Australian Capital Territory'] },
        { 'name': 'Supreme Court of the Northern Territory  Court of Appeal 1986', 'id': 'nt/NTCA', states: ['Others'] },
        { 'name': 'Supreme Court of the Northern Territory  Court of Criminal Appeal 1986', 'id': 'nt/NTCCA', states: ['Others'] },
        { 'name': 'Supreme Court of the Northern Territory  Full Court 1986', 'id': 'nt/NTSCFC', states: ['Others'] },
        { 'name': 'Supreme Court of the Northern Territory 1986', 'id': 'nt/NTSC', states: ['Others'] },

        { 'name': 'County Court of Victoria 1993 ', 'id': 'vic/VCC', states: ['Victoria'] },
        { 'name': 'District Court of New South Wales 1992', 'id': 'nsw/NSWDC', states: ['New South Wales'] },
        { 'name': 'District Court of South Australia 1992 ', 'id': 'sa/SADC', states: ['South Australia'] },
        { 'name': 'District Court of Western Australia 1999', 'id': 'wa/WADC', states: ['Western Australia'] },
        { 'name': 'Queensland District Court 1998', 'id': 'qld/QDC', states: ['Queensland'] },

        // magistrate
        { 'name': "Chief Industrial Magistrate's Court of New South Wales 1999", 'id': 'nsw/NSWCIMC', states: ['New South Wales'] },
        { 'name': 'Childrens Court of Queensland (Magistrates) 2007', 'id': 'qld/QChCM', states: ['Queensland'] },
        { 'name': 'Magistrates Court of Queensland 2006', 'id': 'qld/QMC', states: ['Queensland'] },
        { 'name': 'Magistrates Court of Tasmania 2002', 'id': 'tas/TASMC', states: ['Tasmania'] },
        { 'name': 'Magistrates Court of the Australian Capital Territory 2012', 'id': 'act/ACTMC', states: ['Australian Capital Territory'] },
        { 'name': 'Magistrates Court of the Northern Territory 2002', 'id': 'nt/NTMC', states: ['Others'] },
        { 'name': "Magistrates' Court of Victoria 2006", 'id': 'vic/VMC', states: ['Victoria'] },

        // others
        { 'name': 'ACT Civil and Administrative Tribunal 2009', 'id': 'act/ACAT', states: ['Australian Capital Territory'] },
        { 'name': 'ACT Health Professions Tribunal 20062009', 'id': 'act/ACTHPT', states: ['Australian Capital Territory'] },
        { 'name': 'ACT Ombudsman  Freedom of Information 2018', 'id': 'act/ACTOFOI', states: ['Australian Capital Territory'] },
        { 'name': 'Administrative Appeals Tribunal of the ACT 19932009', 'id': 'act/ACTAAT', states: ['Australian Capital Territory'] },
        { 'name': 'Administrative Appeals Tribunal Reports (Victoria) 19861998', 'id': 'vic/VicAATRp', states: ['Victoria'] },
        { 'name': 'Administrative Decisions Tribunal Appeal Panel of New South Wales 19992013', 'id': 'nsw/NSWADTAP', states: ['New South Wales'] },
        { 'name': 'Administrative Decisions Tribunal of New South Wales 19992013', 'id': 'nsw/NSWADT', states: ['New South Wales'] },
        { 'name': 'AntiDiscrimination Tribunal Queensland 19922009', 'id': 'qld/QADT', states: ['Queensland'] },
        { 'name': 'AntiDiscrimination Tribunal of Tasmania 2000', 'id': 'tas/TASADT', states: ['Tasmania'] },
        { 'name': 'Building Appeals Board of Victoria 2018', 'id': 'vic/VBAB', states: ['Victoria'] },
        { 'name': 'Childrens Court of Queensland 2004', 'id': 'qld/QChC', states: ['Queensland'] },
        { 'name': 'Chiropractic and Osteopathy Board of South Australia  Court Prosecutions 1998', 'id': 'sa/SACHOSBCP', states: ['South Australia'] },
        { 'name': 'Chiropractic and Osteopathy Board of South Australia  Disciplinary Decisions 1992', 'id': 'sa/SACHOSB', states: ['South Australia'] },
        { 'name': 'Chiropractors Tribunal of New South Wales 2008', 'id': 'nsw/NSWCHT', states: ['New South Wales'] },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Administrative and Equal Opportunity Division 2014', 'id': 'nsw/NSWCATAD', states: ['New South Wales'] },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Appeal Panel 2014', 'id': 'nsw/NSWCATAP', states: ['New South Wales'] },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Consumer and Commercial Division 2014', 'id': 'nsw/NSWCATCD', states: ['New South Wales'] },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Guardianship Division 2014', 'id': 'nsw/NSWCATGD', states: ['New South Wales'] },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Occupational Division 2014', 'id': 'nsw/NSWCATOD', states: ['New South Wales'] },
        { 'name': 'Community Services Appeals Tribunal of New South Wales 1998', 'id': 'nsw/csat', states: ['New South Wales'] },
        { 'name': 'Compensation Court of New South Wales 19852003', 'id': 'nsw/NSWCC', states: ['New South Wales'] },
        { 'name': 'Consumer, Trader and Tenancy Tribunal of New South Wales 20022013', 'id': 'nsw/NSWCTTT', states: ['New South Wales'] },
        { 'name': 'Coroners Court of New South Wales 1979', 'id': 'nsw/NSWCorC', states: ['New South Wales'] },
        { 'name': 'Coroners Court of Queensland 2004', 'id': 'qld/QldCorC', states: ['Queensland'] },
        { 'name': 'Coroners Court of South Australia 2000', 'id': 'sa/SACorC', states: ['South Australia'] },
        { 'name': 'Coroners Court of Victoria 2002', 'id': 'vic/VicCorC', states: ['Victoria'] },
        { 'name': 'Coroners Court of Western Australia 2012', 'id': 'wa/WACorC', states: ['Western Australia'] },
        { 'name': 'Coroners Court of the Australian Capital Territory 2013', 'id': 'act/ACTCD', states: ['Australian Capital Territory'] },
        { 'name': 'Criminal Injuries Compensation Assessor of Western Australia 2004 ', 'id': 'wa/WACIC', states: ['Western Australia'] },

        { 'name': 'Dental Board of South Australia 2006', 'id': 'sa/SADB', states: ['South Australia'] },
        { 'name': 'Dental Practice Board of Victoria 2000', 'id': 'vic/VDPB', states: ['Victoria'] },
        { 'name': 'Dental Tribunal of New South Wales 2009', 'id': 'nsw/NSWDT', states: ['New South Wales'] },
        { 'name': 'Discrimination Tribunal of the ACT 19982009', 'id': 'act/ACTDT', states: ['Australian Capital Territory'] },
        { 'name': 'Drug Court of New South Wales 1999', 'id': 'nsw/NSWDRGC', states: ['New South Wales'] },
        { 'name': 'Dust Diseases Tribunal of New South Wales 1989', 'id': 'nsw/NSWDDT', states: ['New South Wales'] },
        { 'name': 'Environment Resources and Development Court of South Australia 1996 ', 'id': 'sa/SAERDC', states: ['South Australia'] },
        { 'name': 'Equal Opportunity Tribunal of South Australia 2004', 'id': 'sa/SAEOT', states: ['South Australia'] },
        { 'name': 'Fair Trading Tribunal of New South Wales 19992001', 'id': 'nsw/NSWFTT', states: ['New South Wales'] },
        { 'name': 'Forest Practices Tribunal of Tasmania 1994', 'id': 'tas/TASFPT', states: ['Tasmania'] },
        { 'name': 'Guardianship Tribunal of New South Wales 19962013', 'id': 'nsw/NSWGT', states: ['New South Wales'] },
        { 'name': 'Guardianship and Administration Board of Western Australia 20042005', 'id': 'wa/WAGAB', states: ['Western Australia'] },
        { 'name': 'Health Practitioners Tribunal of South Australia 2010', 'id': 'sa/SAHPT', states: ['South Australia'] },
        { 'name': 'Heritage Council of Victoria 2011', 'id': 'vic/VHerCl', states: ['Victoria'] },
        { 'name': 'Industrial Court of New South Wales 2014', 'id': 'nsw/NSWIC', states: ['New South Wales'] },
        { 'name': 'Industrial Court of Queensland 2014', 'id': 'qld/ICQ', states: ['Queensland'] },
        { 'name': 'Industrial Court of the Australian Capital Territory 2014', 'id': 'act/ACTIC', states: ['Australian Capital Territory'] },
        { 'name': 'Industrial Relations Commission of New South Wales 1985', 'id': 'nsw/NSWIRComm', states: ['New South Wales'] },
        { 'name': 'Land Appeal Court of Queensland 1985', 'id': 'qld/QLAC', states: ['Queensland'] },
        { 'name': 'Land Court of Queensland 1989', 'id': 'qld/QLC', states: ['Queensland'] },
        { 'name': 'Land and Environment Court of New South Wales 1987', 'id': 'nsw/NSWLEC', states: ['New South Wales'] },
        { 'name': 'Land and Resources Tribunal of Queensland 20002007', 'id': 'qld/QLRT', states: ['Queensland'] },
        { 'name': 'Law Reports (New South Wales) 18561900', 'id': 'nsw/NSWLawRp', states: ['New South Wales'] },
        { 'name': 'Legal Profession Disciplinary Tribunal of Tasmania 2018', 'id': 'tas/TASLPDT', states: ['Tasmania'] },
        { 'name': 'Licensing Court of South Australia 1994 ', 'id': 'sa/SALC', states: ['South Australia'] },
        { 'name': 'Liquor and Gambling Commissioner of South Australia 2002', 'id': 'sa/SALGCmr', states: ['South Australia'] },
        { 'name': 'Medical Board of South Australia 2005', 'id': 'sa/SAMB', states: ['South Australia'] },
        { 'name': 'Medical Board of Western Australia 2010', 'id': 'wa/WAMB', states: ['Western Australia'] },
        { 'name': 'Medical Board of the ACT  Professional Standards Panel Outcomes 2005', 'id': 'act/ACTMBPSP', states: ['Australian Capital Territory'] },
        { 'name': 'Medical Practitioners Board of Victoria  Formal Hearings 2001', 'id': 'vic/VMPB', states: ['Victoria'] },
        { 'name': 'Medical Practitioners Board of Victoria  Professional Standards Panel Hearings 2009', 'id': 'vic/VMPBPSP', states: ['Victoria'] },

        { 'name': 'New South Wales Industrial Gazettes 19021950', 'id': 'nsw/NSWIndGaz', states: ['New South Wales'] },
        { 'name': 'New South Wales Medical Professional Standards Committee 2008', 'id': 'nsw/NSWMPSC', states: ['New South Wales'] },
        { 'name': 'New South Wales Medical Tribunal 20102013', 'id': 'nsw/NSWMT', states: ['New South Wales'] },
        { 'name': 'New South Wales Mental Health Review Tribunal 2013', 'id': 'nsw/NSWMHRT', states: ['New South Wales'] },
        { 'name': 'New South Wales Privacy Commissioner Cases 1997', 'id': 'nsw/NSWPrivCmr', states: ['New South Wales'] },
        { 'name': 'New South Wales State Insurance Regulatory Authority Dispute Resolution Service 2020', 'id': 'nsw/NSWSIRADRS', states: ['New South Wales'] },
        { 'name': 'Nursing and Midwifery Professional Standards Committee of New South Wales 2011', 'id': 'nsw/NSWNMPSC', states: ['New South Wales'] },
        { 'name': 'Nursing and Midwifery Tribunal of New South Wales 2007', 'id': 'nsw/NSWNMT', states: ['New South Wales'] },
        { 'name': 'Office of the Victorian Privacy Commissioner Case Notes 2003', 'id': 'vic/VPrivCmr', states: ['Victoria'] },
        { 'name': 'Optometry Tribunal of New South Wales 2012', 'id': 'nsw/NSWOPT', states: ['New South Wales'] },
        { 'name': 'Osteopathy Tribunal of New South Wales 2010', 'id': 'nsw/NSWOST', states: ['New South Wales'] },

        { 'name': "Pelham's South Australian Reports 18651866", 'id': 'sa/SAPelhamRp', states: ['South Australia'] },
        { 'name': 'Personal Injury Commission of New South Wales  Medical Appeal Panel and Review Panel 2021', 'id': 'nsw/NSWPICMP', states: ['New South Wales'] },
        { 'name': 'Personal Injury Commission of New South Wales  Merit Review 2021', 'id': 'nsw/NSWPICMR', states: ['New South Wales'] },
        { 'name': 'Personal Injury Commission of New South Wales  Merit Review Panel 2021', 'id': 'nsw/NSWPICMRP', states: ['New South Wales'] },
        { 'name': 'Personal Injury Commission of New South Wales  Presidential 2021', 'id': 'nsw/NSWPICPD', states: ['New South Wales'] },
        { 'name': 'Personal Injury Commission of New South Wales 2021', 'id': 'nsw/NSWPIC', states: ['New South Wales'] },
        { 'name': 'Pharmacy Board of New South Wales 19902010', 'id': 'nsw/NSWPB', states: ['New South Wales'] },
        { 'name': 'Pharmacy Board of South Australia 2008', 'id': 'sa/SAPHB', states: ['South Australia'] },
        { 'name': 'Pharmacy Tribunal of New South Wales 2009', 'id': 'nsw/NSWPHT', states: ['New South Wales'] },
        { 'name': 'Physiotherapists Registration Board of Victoria 2008', 'id': 'vic/VPYRB' },
        { 'name': 'Physiotherapists Tribunal of New South Wales 2007', 'id': 'nsw/NSWPYT' },
        { 'name': 'Planning Appeals Board Reports (Victoria) 19791987', 'id': 'vic/VicPABRp' },
        { 'name': 'Planning Panels Victoria 1988', 'id': 'vic/PPV', states: ['Victoria'] },
        { 'name': 'Podiatry Board of South Australia 2007', 'id': 'sa/SAPDB', states: ['South Australia'] },
        { 'name': 'Podiatry Tribunal of New South Wales 2010', 'id': 'nsw/NSWPDT', states: ['New South Wales'] },
        { 'name': 'Psychologists Registration Board of Victoria 1999', 'id': 'vic/VPSRB', states: ['Victoria'] },
        { 'name': 'Psychologists Tribunal of New South Wales 2007', 'id': 'nsw/NSWPST', states: ['New South Wales'] },
        { 'name': 'Queensland Body Corporate and Community Management Commissioner  Adjudicators Orders 2000', 'id': 'qld/QBCCMCmr', states: ['Queensland'] },
        { 'name': 'Queensland Building Tribunal 19942003', 'id': 'qld/QBT', states: ['Queensland'] },
        { 'name': 'Queensland Children Services Tribunal 20032009', 'id': 'qld/QCST', states: ['Queensland'] },
        { 'name': 'Queensland Civil and Administrative Tribunal 2009', 'id': 'qld/QCAT', states: ['Queensland'] },
        { 'name': 'Queensland Civil and Administrative Tribunal Appeals 2010', 'id': 'qld/QCATA', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Architects List 20042009', 'id': 'qld/QCCTA', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Body Corporate and Community Management List 20072009', 'id': 'qld/QCCTBCCM', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Building List 20032009', 'id': 'qld/QCCTB', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Engineers List 20032009', 'id': 'qld/QCCTE', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Gaming List 20042009', 'id': 'qld/QCCTG', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Liquor List 20032009', 'id': 'qld/QCCTL', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Manufactured Homes List 20042009', 'id': 'qld/QCCTMH', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Plumbers and Drainers List 20042009', 'id': 'qld/QCCTPD', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Property Agents and Motor Dealers List 20032009', 'id': 'qld/QCCTPAMD', states: ['Queensland'] },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Retirement Villages List 20032009', 'id': 'qld/QCCTRV', states: ['Queensland'] },
        { 'name': 'Queensland Guardianship and Administration Tribunal 20002009', 'id': 'qld/QGAAT', states: ['Queensland'] },
        { 'name': 'Queensland Industrial Court 19992009', 'id': 'qld/QIC', states: ['Queensland'] },
        { 'name': 'Queensland Industrial Gazettes 19151950', 'id': 'qld/QldIndGaz', states: ['Queensland'] },
        { 'name': 'Queensland Industrial Relations Commission 19992014', 'id': 'qld/QIRComm', states: ['Queensland'] },
        { 'name': 'Queensland Industrial Relations Commission 2014', 'id': 'qld/QIRC', states: ['Queensland'] },
        { 'name': 'Queensland Information Commissioner 1993', 'id': 'qld/QICmr', states: ['Queensland'] },
        { 'name': 'Queensland Legal Practice Tribunal 20042009', 'id': 'qld/QLPT', states: ['Queensland'] },
        { 'name': 'Queensland Liquor Appeals Tribunal 19922003', 'id': 'qld/QLAT', states: ['Queensland'] },
        { 'name': 'Queensland Mental Health Court 2002', 'id': 'qld/QMHC', states: ['Queensland'] },
        { 'name': 'Queensland Mining Wardens 19932000', 'id': 'qld/QMW', states: ['Queensland'] },
        { 'name': 'Queensland Nursing Tribunal 2004', 'id': 'qld/QNT', states: ['Queensland'] },
        { 'name': 'Queensland Planning and Environment Court 1999', 'id': 'qld/QPEC', states: ['Queensland'] },
        { 'name': 'Queensland Property Agents and Motor Dealers Tribunal 20022003', 'id': 'qld/QPAMDT', states: ['Queensland'] },
        { 'name': 'Queensland Racing Appeals Authority 20022003', 'id': 'qld/QRAA', states: ['Queensland'] },
        { 'name': 'Queensland Retirement Villages Tribunal 20012002', 'id': 'qld/QRVT', states: ['Queensland'] },

        { 'name': 'Racing Appeals Tribunal of Queensland 20032009', 'id': 'qld/QRAT', states: ['Queensland'] },
        { 'name': 'Residential Tenancies Tribunal of the ACT 19982009', 'id': 'act/ACTRTT', states: ['Australian Capital Territory'] },
        { 'name': 'Residential Tribunal of New South Wales 19872002', 'id': 'nsw/NSWR', states: ['New South Wales'] },
        { 'name': 'Resource Management and Planning Appeal Tribunal of Tasmania 1994', 'id': 'tas/TASRMPAT', states: ['Tasmania'] },
        { 'name': 'Resource Planning and Development Commission of Tasmania 20042009', 'id': 'tas/TASRPDComm', states: ['Tasmania'] },
        { 'name': 'Social Security Appeals Tribunal of Australia  Review of Child Support Agency 20072015', 'id': 'cth/SSATACSA', states: ['Others'] },
        { 'name': 'South Australian Civil and Administrative Tribunal 2015', 'id': 'sa/SACAT', states: ['South Australia'] },
        { 'name': 'South Australian Dental Professional Conduct Tribunal 1998', 'id': 'sa/SADPCT', states: ['South Australia'] },
        { 'name': 'South Australian Employment Tribunal 2015', 'id': 'sa/SAET', states: ['South Australia'] },
        { 'name': 'South Australian Industrial Relations Commission 1991', 'id': 'sa/SAIRComm', states: ['South Australia'] },
        { 'name': 'South Australian Industrial Relations Court 1992 ', 'id': 'sa/SAIRC', states: ['South Australia'] },
        { 'name': 'South Australian Industrial Reports 19161945', 'id': 'sa/SAIndRp', states: ['South Australia'] },
        { 'name': 'South Australian Law Reports 18631920', 'id': 'sa/SALawRp', states: ['South Australia'] },
        { 'name': 'South Australian Ombudsman FOI Determinations 2011', 'id': 'sa/SAOmbFOI', states: ['South Australia'] },
        { 'name': 'South Australian Psychological Board  Court Prosecutions 1992', 'id': 'sa/SAPSBCP', states: ['South Australia'] },
        { 'name': 'South Australian Psychological Board  Disciplinary Decisions 1991', 'id': 'sa/SAPSB', states: ['South Australia'] },
        { 'name': 'South Australian Residential Tenancies Tribunal  Residential Parks 2008', 'id': 'sa/SARTTRP', states: ['South Australia'] },
        { 'name': 'South Australian Residential Tenancies Tribunal 1996', 'id': 'sa/SARTT', states: ['South Australia'] },
        { 'name': 'South Australian Return to Work Premium Review 2019', 'id': 'sa/SARTWPR', states: ['South Australia'] },
        { 'name': 'South Australian Return to Work Premium Review Panel 2015', 'id': 'sa/SARTWPRP', states: ['South Australia'] },
        { 'name': 'South Australian State Reports 19201950', 'id': 'sa/SAStRp', states: ['South Australia'] },
        { 'name': 'South Australian Wardens Court 1994', 'id': 'sa/SAWC', states: ['South Australia'] },
        { 'name': 'South Australian WorkCover Levy Review Panel 20002012', 'id': 'sa/SAWLRP', states: ['South Australia'] },
        { 'name': 'South Australian WorkCover Premium Review Panel 2012', 'id': 'sa/SAWPRP', states: ['South Australia'] },
        { 'name': 'South Australian Workers Compensation Appeal Tribunal 1991', 'id': 'sa/SAWCAT', states: ['South Australia'] },
        { 'name': 'South Australian Workers Compensation Review Panel 19872000', 'id': 'sa/SAWCRP', states: ['South Australia'] },
        { 'name': 'South Australian Workers Compensation Tribunal 1996', 'id': 'sa/SAWCT', states: ['South Australia'] },
        { 'name': 'State Administrative Tribunal of Western Australia 2005', 'id': 'wa/WASAT', states: ['Western Australia'] },
        { 'name': 'State Reports (New South Wales) 19011950', 'id': 'nsw/NSWStRp', states: ['New South Wales'] },
        { 'name': 'Strata Schemes Board of New South Wales 19972001', 'id': 'nsw/NSWSSB', states: ['New South Wales'] },
        { 'name': 'Strata Titles Referee of Western Australia 2004', 'id': 'wa/WASTR', states: ['Western Australia'] },

        { 'name': 'Tasmanian Civil and Administrative Tribunal 2021', 'id': 'tas/TASCAT', states: ['Tasmania'] },
        { 'name': 'Tasmanian Coroners Court 2001', 'id': 'tas/TasCorC', states: ['Tasmania'] },
        { 'name': 'Tasmanian Guardianship and Administration Board 1998', 'id': 'tas/TASGAB', states: ['Tasmania'] },
        { 'name': 'Tasmanian Health Practitioners Tribunal 2011', 'id': 'tas/TASHPT', states: ['Tasmania'] },
        { 'name': 'Tasmanian Law Reports 18971940', 'id': 'tas/TASLawRp', states: ['Tasmania'] },
        { 'name': 'Tasmanian Motor Accidents Compensation Tribunal 1976', 'id': 'tas/TASMACT', states: ['Tasmania'] },
        { 'name': 'Tasmanian Planning Commission 2009', 'id': 'tas/TASPComm', states: ['Tasmania'] },
        { 'name': 'Tasmanian Reports 19581991', 'id': 'tas/TASRp', states: ['Tasmania'] },
        { 'name': 'Tasmanian State Reports 19411978', 'id': 'tas/TASStRp', states: ['Tasmania'] },
        { 'name': 'Tenancy Tribunal of the ACT 19961998', 'id': 'act/ACTTT', states: ['Australian Capital Territory'] },
        { 'name': 'The Victorian Law Times and Legal Observer 18561857', 'id': 'vic/VicLawTLegO', states: ['Victoria'] },
        { 'name': 'Town Planning Appeal Tribunal of Western Australia 20032004', 'id': 'wa/WATPAT', states: ['Western Australia'] },
        { 'name': 'Victorian AntiDiscrimination Tribunal 19951998', 'id': 'vic/VADT', states: ['Victoria'] },
        { 'name': 'Victorian Civil and Administrative Tribunal 1998', 'id': 'vic/VCAT', states: ['Victoria'] },
        { 'name': 'Victorian Civil and Administrative Tribunal/Administrative Appeals Tribunal of Victoria 19851999', 'id': 'vic/VICCAT', states: ['Victoria'] },
        { 'name': 'Victorian Commission for Gambling and Liquor Regulation 2016', 'id': 'vic/VCGLR', states: ['Victoria'] },
        { 'name': 'Victorian Domestic Building Tribunal 19961998', 'id': 'vic/VDBT', states: ['Victoria'] },
        { 'name': 'Victorian Information Commissioner 2019', 'id': 'vic/VICmr', states: ['Victoria'] },
        { 'name': 'Victorian Law Reports 18741956', 'id': 'vic/VicLawRp', states: ['Victoria'] },
        { 'name': 'Victorian Legal Profession Tribunal 19972005', 'id': 'vic/VLPT', states: ['Victoria'] },
        { 'name': 'Victorian Legal Services Commissioner 2016', 'id': 'vic/VLSC', states: ['Victoria'] },
        { 'name': 'Victorian Mental Health Review Board 1987', 'id': 'vic/VMHRB', states: ['Victoria'] },
        { 'name': 'Victorian Mental Health Tribunal 2014', 'id': 'vic/VMHT', states: ['Victoria'] },
        { 'name': 'Victorian Planning Reports 19982013', 'id': 'vic/VicPRp', states: ['Victoria'] },
        { 'name': 'Victorian Racing Appeals Tribunal 19842009', 'id': 'vic/VRAT', states: ['Victoria'] },
        { 'name': 'Victorian Reports 19531996', 'id': 'vic/VicRp', states: ['Victoria'] },

        { 'name': "Webb, A'Beckett and William's Reports (Victoria) 18701872", 'id': 'vic/VicWABWRp', states: ['Victoria'] },
        { 'name': 'Western Australian Building Disputes Tribunal 20002011', 'id': 'wa/WABDT', states: ['Western Australia'] },
        { 'name': 'Western Australian Industrial Relations Commission 19912016', 'id': 'wa/WAIRComm', states: ['Western Australia'] },
        { 'name': 'Western Australian Industrial Relations Commission 2017', 'id': 'wa/WAIRC', states: ['Western Australia'] },
        { 'name': 'Western Australian Information Commissioner 1994', 'id': 'wa/WAICmr', states: ['Western Australia'] },
        { 'name': 'Western Australian Law Reports 18981958', 'id': 'wa/WALawRp', states: ['Western Australia'] },
        { 'name': 'Western Australian Warden of Mines 1979', 'id': 'wa/WAWM', states: ['Western Australia'] },
        { 'name': 'Workers Compensation Commission of New South Wales  Presidential 2002', 'id': 'nsw/NSWWCCPD', states: ['New South Wales'] },
        { 'name': 'Workers Rehabilitation and Compensation Tribunal of Tasmania 2004', 'id': 'tas/TASWRCT', states: ['Tasmania'] },
        { 'name': "Wyatt and Webb's Reports (Victoria) 18611863", 'id': 'vic/VicWWRp', states: ['Victoria'] },
        { 'name': "Wyatt, Webb and A'Beckett's Reports (Victoria) 18641869", 'id': 'vic/VicWWABRp', states: ['Victoria'] },
      ],
      "Others": [
        // high court
        { 'name': 'High Court of Australia 1903', 'id': 'cth/HCA', states: ['Others'] },
        { 'name': 'High Court of Australia Special Leave Dispositions 2008', 'id': 'cth/HCASL', states: ['Others'] },
        { 'name': 'High Court of Australia Transcripts 1983', 'id': 'cth/HCATrans', states: ['Others'] },

        // federal
        { 'name': 'Federal Court of Australia  Full Court 2002', 'id': 'cth/FCAFC', states: ['Others'] },
        { 'name': 'Federal Court of Australia 1977', 'id': 'cth/FCA', states: ['Others'] },

        // family
        { 'name': 'Family Court of Australia  Full Court 2008', 'id': 'cth/FamCAFC', states: ['Others'] },
        { 'name': 'Family Court of Australia 1976', 'id': 'cth/FamCA', states: ['Others'] },

        // federal circuit
        { 'name': 'Federal Circuit Court of Australia 2013', 'id': 'cth/FCCA', states: ['Others'] },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 1 Appellate Jurisdiction 2021', 'id': 'cth/FedCFamC1A', states: ['Others'] },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 1 First Instance 2021', 'id': 'cth/FedCFamC1F', states: ['Others'] },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 2 General Federal Law 2021', 'id': 'cth/FedCFamC2G', states: ['Others'] },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 2 Family Law 2021', 'id': 'cth/FedCFamC2F', states: ['Others'] },

        // magistrates
        { 'name': 'Federal Magistrates Court of Australia  Family Law 20002013', 'id': 'cth/FMCAfam', states: ['Others'] },
        { 'name': 'Federal Magistrates Court of Australia 20002013', 'id': 'cth/FMCA', states: ['Others'] },

        // others
        { 'name': 'Federal Privacy Commissioner of Australia Case Notes 19892011', 'id': 'cth/PrivCmrA', states: ['Others'] },
        { 'name': 'Federal Privacy Commissioner of Australia Complaint Determinations 19932004', 'id': 'cth/PrivCmrACD', states: ['Others'] },
        { 'name': 'Administrative Appeals Tribunal 1976', 'id': 'cth/AATA', states: ['Others'] },
        { 'name': 'Argus Law Reports 18951950', 'id': 'cth/ArgusLawRp', states: ['Others'] },
        { 'name': 'Argus Law Reports, Current Notes 18961950', 'id': 'cth/ArgusLawRpCN', states: ['Others'] },
        { 'name': 'Australia Domain Name 2003', 'id': 'cth/AUDND', states: ['Others'] },
        { 'name': 'Australian Coal Industry Tribunal 19401995', 'id': 'cth/ACIndT', states: ['Others'] },
        { 'name': 'Australian Competition Tribunal 1997', 'id': 'cth/ACompT', states: ['Others'] },
        { 'name': 'Australian Designs Office 1982', 'id': 'cth/ADO', states: ['Others'] },
        { 'name': 'Australian Industrial Relations Commission  Full Bench 20072009', 'id': 'cth/AIRCFB', states: ['Others'] },
        { 'name': 'Australian Industrial Relations Commission 19832009', 'id': 'cth/AIRC', states: ['Others'] },
        { 'name': 'Australian Information Commissioner 2011', 'id': 'cth/AICmr', states: ['Others'] },
        { 'name': 'Australian Information Commissioner Case Notes 2011', 'id': 'cth/AICmrCN', states: ['Others'] },
        { 'name': 'Australian Patent Office 1981', 'id': 'cth/APO', states: ['Others'] },
        { 'name': "Australian Plant Breeder's Rights Office Decisions 2016", 'id': 'cth/APBRO', states: ['Others'] },
        { 'name': 'Australian Takeovers Panel 2000', 'id': 'cth/ATP', states: ['Others'] },
        { 'name': 'Australian Trade Marks Office 1991', 'id': 'cth/ATMO', states: ['Others'] },
        { 'name': 'Australian Trade Marks Office Geographical Indication 2008', 'id': 'cth/ATMOGI', states: ['Others'] },
        { 'name': 'Commonwealth Arbitration Reports 19051993', 'id': 'cth/CthArbRp', states: ['Others'] },
        { 'name': 'Copyright Tribunal 1982', 'id': 'cth/ACopyT', states: ['Others'] },
        { 'name': 'Defence Force Discipline Appeal Tribunal 1999', 'id': 'cth/ADFDAT', states: ['Others'] },
        { 'name': 'Fair Work Australia  Enterprise Agreement 20092012', 'id': 'cth/FWAA', states: ['Others'] },
        { 'name': 'Fair Work Australia  Full Bench 20092012', 'id': 'cth/FWAFB', states: ['Others'] },
        { 'name': 'Fair Work Australia 20092012', 'id': 'cth/FWA', states: ['Others'] },
        { 'name': 'Fair Work Commission  Enterprise Agreement 2013', 'id': 'cth/FWCA', states: ['Others'] },
        { 'name': 'Fair Work Commission  Full Bench 2013', 'id': 'cth/FWCFB', states: ['Others'] },
        { 'name': 'Fair Work Commission  General Manager and Delegates 2013', 'id': 'cth/FWCD', states: ['Others'] },
        { 'name': 'Fair Work Commission 2013', 'id': 'cth/FWC', states: ['Others'] },
        { 'name': 'Human Rights and Equal Opportunity Commission 19852001', 'id': 'cth/HREOCA', states: ['Others'] },
        { 'name': 'Immigration Review Tribunal 19901999', 'id': 'cth/IRTA', states: ['Others'] },
        { 'name': 'Industrial Relations Court of Australia 19942002', 'id': 'cth/IRCA', states: ['Others'] },
        { 'name': 'Insurance Ombudsman Service Limited Privacy Compliance Committee Complaint Determinations 20042005', 'id': 'cth/IOSPCC', states: ['Others'] },
        { 'name': 'Local Court of the Northern Territory 2016', 'id': 'nt/NTLC', states: ['Others'] },
        { 'name': 'Migration Review Tribunal 19992015', 'id': 'cth/MRTA', states: ['Others'] },
        { 'name': 'National Native Title Tribunal 1994', 'id': 'cth/NNTTA', states: ['Others'] },
        { 'name': 'Northern Territory AntiDiscrimination Commission 1996', 'id': 'nt/NTADComm', states: ['Others'] },
        { 'name': 'Northern Territory Civil and Administrative Tribunal 2015', 'id': 'nt/NTCAT', states: ['Others'] },
        { 'name': 'Northern Territory Consumer Affairs Residential Building Disputes 2016', 'id': 'nt/NTCARB', states: ['Others'] },
        { 'name': 'Northern Territory Coroners Court 1979', 'id': 'nt/NTCorC', states: ['Others'] },
        { 'name': 'Northern Territory Health Professional Review Tribunal 2010', 'id': 'nt/NTHPRT', states: ['Others'] },
        { 'name': 'Northern Territory Judgments 19181976', 'id': 'nt/NTJud', states: ['Others'] },
        { 'name': 'Northern Territory Mental Health Review Tribunal 2015', 'id': 'nt/NTMHRT', states: ['Others'] },
        { 'name': 'Northern Territory Residential Tenancies Commissioner 2006', 'id': 'nt/NTRTCmr', states: ['Others'] },
        { 'name': 'Privy Council Appeals 19031980', 'id': 'cth/UKPCHCA', states: ['Others'] },
        { 'name': 'Refugee Review Tribunal 19932015', 'id': 'cth/RRTA', states: ['Others'] },
        { 'name': 'Superannuation Complaints Tribunal of Australia 1995', 'id': 'cth/SCTA', states: ['Others'] },
      ],
    },
  },

  'CAN': {
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Others"
    ],
    hierarchy: {
      "Supreme Courts": "(Supreme Court)|(Court of Appeal)|(Court of Queen's Bench)|(Superior Court)",
      "Intermediate Courts": "(Provincial Court)|(Family Court)",
    },
    courts: {
      "Canada Jurisdiction": [
        // Supreme Court
        { "name": "Supreme Court of British Columbia","id": "21", states: ['British Columbia'] },
        {"name": "Supreme Court of Canada","id": "1", states: ['Others'] },
        {"name": "Supreme Court of Canada - Applications for Leave","id": "2", states: ['Others'] },
        {"name": "Supreme Court of Newfoundland and Labrador","id": "99", states: ['Newfoundland and Labrador'] },
        {"name": "Supreme Court of Nova Scotia","id": "87", states: ['Nova Scotia'] },
        {"name": "Supreme Court of Nova Scotia (Family Division)","id": "88", states: ['Nova Scotia'] },
        {"name": "Supreme Court of Prince Edward Island","id": "96", states: ['Prince Edward Island'] },
        {"name": "Supreme Court of Yukon","id": "102", states: ['Others'] },
        {"name": "Supreme Court of the Northwest Territories","id": "106", states: ['Others'] },
        
        {"name": "Court Martial Appeal Court of Canada","id": "7", states: ['Others'] },
        {"name": "Courts Martial","id": "8", states: ['Others'] },

        {"name": "Court of Appeal for British Columbia","id": "20", states: ['British Columbia'] },
        {"name": "Court of Appeal for Ontario","id": "45", states: ['Ontario'] },
        {"name": "Court of Appeal for Saskatchewan","id": "34", states: ['Saskatchewan'] },
        {"name": "Court of Appeal for the Northwest Territories","id": "105", states: ['Others'] },
        { "name": "Court of Appeal of Alberta","id": "29", states: ['Alberta'] },
        {"name": "Court of Appeal of Manitoba","id": "42", states: ['Manitoba'] },
        {"name": "Court of Appeal of New Brunswick","id": "81", states: ['New Brunswick'] },
        {"name": "Court of Appeal of Newfoundland and Labrador","id": "98", states: ['Newfoundland and Labrador'] },
        {"name": "Court of Appeal of Nunavut","id": "111", states: ['Others'] },
        {"name": "Court of Appeal of Quebec","id": "67", states: ['Quebec'] },
        {"name": "Court of Appeal of Yukon","id": "101", states: ['Others'] },
        {"name": "Nova Scotia Court of Appeal","id": "86", states: ['Nova Scotia'] },
        {"name": "Prince Edward Island Court of Appeal","id": "95", states: ['Prince Edward Island'] },
                        
        {"name": "Federal Court of Appeal","id": "3", states: ['Others'] },
        {"name": "Provincial Court","id": "83", states: ['Others'] },
        {"name": "Provincial Court of British Columbia","id": "22", states: ['British Columbia'] },
        {"name": "Provincial Court of Manitoba","id": "44", states: ['Manitoba'] },
        {"name": "Provincial Court of Newfoundland and Labrador","id": "100", states: ['Newfoundland and Labrador'] },
        {"name": "Provincial Court of Nova Scotia","id": "89", states: ['Nova Scotia'] },
        {"name": "Provincial Court of Prince Edward Island","id": "97", states: ['Prince Edward Island'] },
        {"name": "Provincial Court of Saskatchewan","id": "36", states: ['Saskatchewan'] },
        {"name": "Superior Court","id": "68", states: ['Others'] },
        {"name": "Superior Court of Justice", "id": "46", states: ['Others'] },
        {"name": "Territorial Court of Yukon", "id": "103", states: ['Others'] },
        {"name": "Territorial Court of the Northwest Territories", "id": "107", states: ['Others'] },
        {"name": "Federal Court", "id": "4", states: ['Others'] },
        {"name": "Tax Court of Canada", "id": "5", states: ['Others'] },
        {"name": "Administrative Tribunal of Québec", "id": "73", states: ['Quebec'] },
        {"name": "Agriculture, Food & Rural Affairs Appeal Tribunal", "id": "50", states: ['Others'] },
        {"name": "Alberta Land and Property Rights Tribunal", "id": "32", states: ['Others'] },
        {"name": "Appeal Tribunal under the Medical Profession Act", "id": "40", states: ['Others'] },
        {"name": "British Columbia Employment Standards Tribunal", "id": "23", states: ['British Columbia'] },
        {"name": "British Columbia Human Rights Tribunal", "id": "24", states: ['British Columbia'] },
        {"name": "British Columbia Workers' Compensation Appeal Tribunal", "id": "25", states: ['British Columbia'] },
        {"name": "Canada Agricultural Review Tribunal", "id": "9", states: ['Others'] },
        {"name": "Canadian Human Rights Tribunal", "id": "10", states: ['Others'] },
        {"name": "Canadian International Trade Tribunal", "id": "11", states: ['Others'] },
        {"name": "Capital Markets Tribunal", "id": "51", states: ['Others'] },
        {"name": "Civil Resolution Tribunal of British Columbia", "id": "26", states: ['Others'] },
        {"name": "Competition Tribunal", "id": "12", states: ['Others'] },
        {"name": "Condominium Authority Tribunal", "id": "52", states: ['Others'] },
        {"name": "Energy Resource Appeal Tribunal", "id": "27", states: ['Others'] },
        {"name": "Environmental Protection Tribunal of Canada", "id": "13", states: ['Others'] },
        {"name": "Environmental Review Tribunal", "id": "53", states: ['Others'] },
        {"name": "Financial Services Tribunal", "id": "28", states: ['Others'] },
        {"name": "Financial Services Tribunal", "id": "54", states: ['Others'] },
        {"name": "Financial and Consumer Services Tribunal", "id": "84", states: ['Others'] },
        {"name": "Horse Racing Alberta Appeal Tribunal", "id": "33", states: ['Others'] },
        {"name": "Human Rights Tribunal", "id": "70", states: ['Others'] },
        {"name": "Human Rights Tribunal of Ontario", "id": "55", states: ['Others'] },
        {"name": "Law Society Tribunal", "id": "56", states: ['Others'] },
        {"name": "Local Planning Appeal Tribunal", "id": "57", states: ['Others'] },
        {"name": "Mining and Lands Tribunal", "id": "58", states: ['Others'] },
        {"name": "Northwest Territories Assessment Appeal Tribunal", "id": "110", states: ['Others'] },
        {"name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal", "id": "109", states: ['Others'] },
        {"name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal", "id": "114", states: ['Others'] },
        {"name": "Nova Scotia Labour Standards Tribunal", "id": "93", states: ['Nova Scotia'] },
        {"name": "Nova Scotia Workers' Compensation Appeals Tribunal", "id": "94", states: ['Nova Scotia'] },
        {"name": "Nunavut Human Rights Tribunal", "id": "115", states: ['Others'] },
        {"name": "Occupational Health and Safety Tribunal Canada", "id": "14", states: ['Others'] },
        {"name": "Ontario Land Tribunal", "id": "60", states: ['Ontario'] },
        {"name": "Ontario Licence Appeal Tribunal", "id": "61", states: ['Ontario'] },
        {"name": "Ontario Pay Equity Hearings Tribunal", "id": "62", states: ['Ontario'] },
        {"name": "Ontario Physicians and Surgeons Discipline Tribunal", "id": "63", states: ['Ontario'] },
        {"name": "Ontario Social Benefits Tribunal", "id": "64", states: ['Ontario'] },
        {"name": "Ontario Special Education (English) Tribunal", "id": "65", states: ['Ontario'] },
        {"name": "Ontario Workplace Safety and Insurance Appeals Tribunal", "id": "66", states: ['Ontario'] },
        {"name": "Professions Tribunal", "id": "71", states: ['Others'] },
        {"name": "Public Servants Disclosure Protection Tribunal", "id": "15", states: ['Others'] },
        {"name": "Public Service Staffing Tribunal", "id": "16", states: ['Others'] },
        {"name": "Saskatchewan Human Rights Tribunal", "id": "41", states: ['Saskatchewan'] },
        {"name": "Social Security Tribunal of Canada", "id": "17", states: ['Others'] },
        {"name": "Specific Claims Tribunal Canada", "id": "18", states: ['Others'] },
        {"name": "Transportation Appeal Tribunal of Canada", "id": "19", states: ['Others'] },
        {"name": "Tribunal administratif des marchés financiers", "id": "76", states: ['Others'] },
        {"name": "Tribunal administratif du logement", "id": "77", states: ['Others'] },
        {"name": "Tribunal administratif du travail", "id": "78", states: ['Others'] },
        {"name": "Tribunal d'arbitrage (RQ and CARRA)", "id": "80", states: ['Others'] },
        {"name": "Tribunal d'arbitrage (performing, recording and film artists)", "id": "79", states: ['Others'] },
        {"name": "Workers’ Compensation Appeals Tribunal", "id": "85", states: ['Others'] },
        // Others
        {"name": "Alberta Court of Justice", "id": "31", states: ['Others'] },
        {"name": "Court of King's Bench for Saskatchewan", "id": "35", states: ['Saskatchewan'] },
        {"name": "Court of King's Bench of Alberta", "id": "30", states: ['Alberta'] },
        {"name": "Court of King's Bench of Manitoba", "id": "43", states: ['Manitoba'] },
        {"name": "Court of King's Bench of New Brunswick", "id": "82", states: ['New Brunswick'] },
        {"name": "Court of Quebec", "id": "69", states: ['Quebec'] },
        {"name": "Divisional Court", "id": "47", states: ['Others'] },
        {"name": "Exchequer Court of Canada", "id": "6", states: ['Others'] },
        {"name": "Labour Court", "id": "75", states: ['Others'] },
        {"name": "Municipal Courts", "id": "72", states: ['Others'] },
        {"name": "Nova Scotia Family Court", "id": "92", states: ['Nova Scotia'] },
        {"name": "Nova Scotia Probate Court", "id": "91", states: ['Nova Scotia'] },
        {"name": "Nunavut Court of Justice", "id": "112", states: ['Others'] },
        {"name": "Ontario Court of Justice", "id": "49", states: ['Ontario'] },
        {"name": "Ontario Court of the Drainage Referee", "id": "59", states: ['Ontario'] },
        {"name": "Saskatchewan District Court", "id": "37", states: ['Saskatchewan'] },
        {"name": "Saskatchewan Surrogate Court", "id": "38", states: ['Saskatchewan'] },
        {"name": "Saskatchewan Unified Family Court", "id": "39", states: ['Saskatchewan'] },
        {"name": "Small Claims Court", "id": "48", states: ['Others'] },
        {"name": "Small Claims Court", "id": "90", states: ['Others'] },
        {"name": "Small Claims Court of the Yukon", "id": "104", states: ['Others'] },
        {"name": "Youth Justice Court", "id": "108", states: ['Others'] },
        {"name": "Youth Justice Court of Nunavut", "id": "113", states: ['Others'] }
      ],
    },
  },

  'NZ': {
    states: [
      "New Zealand",
      "Auckland",
      "Bay of Plenty|(Tauranga)",
      "Canterbury|(Environment)|(Family)",
      "Gisborne",
      "Hawke's Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago|(Queenstown)|(Environment)",
      "Southland",
      "Taranaki",
      "Waikato|(Hamilton)",
      "Wellington",
      "West Coast",
      "Chatham Islands"
    ],
    hierarchy: {
      "District Court": "(District Court)|(District)",
      "High Court": "(High Court)",
      "Family Court": "(Family Court)",
      "Tribunals": "(Tribunal)|(Employment Relations)",
      "Specialist Courts": "(Environment Court)",
    },
    courts: {
      "New Zealand Jurisdiction":[
        // District Courts
        { "name": "District Court of New Zealand 1981", "id": "NZDC", states: ['New Zealand'] },
        { "name": "Family Court of New Zealand 1997", "id": "NZFC", states: ['New Zealand'] },
        { "name": "New Zealand District Licensing Committee Auckland 2014", "id": "NZDLCAK", states: ['Auckland'] },
        { "name": "New Zealand District Licensing Committee Hamilton 2014", "id": "NZDLCHAM", states: ['Waikato'] },
        { "name": "New Zealand District Licensing Committee Porirua 2014", "id": "NZDLCPOR", states: ['Wellington'] },
        { "name": "New Zealand District Licensing Committee Queenstown Lakes District Council 2015", "id": "NZDLCQLDC", states: ['Otago'] },
        { "name": "New Zealand District Licensing Committee Tauranga 2021", "id": "NZDLCTGA", states: ['Bay of Plenty'] },
        { "name": "New Zealand District Licensing Committee Waipa 2016", "id": "NZDLCWP", states: ['Waikato'] },
        { "name": "New Zealand District Licensing Committee Wellington 2014", "id": "NZDLCWN", states: ['Wellington'] },
        { "name": "New Zealand Youth Court 2005", "id": "NZYC", states: ['New Zealand'] },
        // High Courts
        {'name': 'Environment Court of New Zealand 1996','id': 'NZEnvC', states: ['New Zealand'] },
        { 'name': 'High Court of New Zealand 1847', 'id': 'NZHC', states: ['New Zealand'] },
        {'name': 'Social Security Appeal Authority of New Zealand Decisions 2003','id': 'NZSSAA', states: ['New Zealand'] },
        {'name': 'Student Allowance Appeal Authority of New Zealand Decisions 2004','id': 'NZSAAA', states: ['New Zealand'] },
        // Tribunals
        { "name": "New Zealand Agricultural Tribunal 1979-1985", "id": "NZAgrT", states: ['New Zealand'] },
        { "name": "New Zealand Aircrew Industrial Tribunal 1981-1987", "id": "NZAIT", states: ['New Zealand'] },
        { "name": "New Zealand Broadcasting Tribunal 1980-1990", "id": "NZBT", states: ['New Zealand'] },
        { "name": "New Zealand Canterbury Earthquakes Insurance Tribunal 2019", "id": "NZCEIT", states: ['Canterbury'] },
        { "name": "New Zealand Complaints Review Tribunal Decisions 1993-2007", "id": "NZCRT", states: ['New Zealand'] },
        { "name": "New Zealand Copyright Tribunal Decisions 1977", "id": "NZCopyT", states: ['New Zealand'] },
        { "name": "New Zealand Deportation Review Tribunal Decisions 1998-2010", "id": "NZDRT", states: ['New Zealand'] },
        { "name": "New Zealand Disputes Tribunal Decisions 2006", "id": "NZDispT", states: ['New Zealand'] },
        { "name": "New Zealand Employment Tribunal 1999-2003", "id": "NZEmpT", states: ['New Zealand'] },
        { "name": "New Zealand Equal Opportunities Tribunal 1980-1993", "id": "NZEOT", states: ['New Zealand'] },
        { "name": "New Zealand Health Practitioners Disciplinary Tribunal 2004", "id": "NZHPDT", states: ['New Zealand'] },
        { "name": "New Zealand Human Rights Review Tribunal Decisions 2002", "id": "NZHRRT", states: ['New Zealand'] },
        { "name": "New Zealand Immigration Advisers Complaints and Disciplinary Tribunal 2010", "id": "NZIACDT", states: ['New Zealand'] },
        { "name": "New Zealand Immigration and Protection Tribunal Decisions 2010", "id": "NZIPT", states: ['New Zealand'] },
        { "name": "New Zealand Indecent Publications Tribunal 1964-1996", "id": "NZIndPubT", states: ['New Zealand'] },
        { "name": "New Zealand Land Valuation Tribunal Decisions 1994", "id": "NZLVT", states: ['New Zealand'] },
        { "name": "New Zealand Lawyers and Conveyancers Disciplinary Tribunal Decisions 2009", "id": "NZLCDT", states: ['New Zealand'] },
        { "name": "New Zealand Legal Aid Tribunal 2011", "id": "NZLAT", states: ['New Zealand'] },
        { "name": "New Zealand Medical Practitioners Disciplinary Tribunal 1997-2010", "id": "NZMPDT", states: ['New Zealand'] },
        { "name": "New Zealand Mental Health Review Tribunal 2009", "id": "NZMHRT", states: ['New Zealand'] },
        { "name": "New Zealand Motor Vehicle Disputes Tribunal Decisions 1987", "id": "NZMVDT", states: ['New Zealand'] },
        { "name": "New Zealand Planning Tribunal 1978-1996", "id": "NZPT", states: ['New Zealand'] },
        { "name": "New Zealand Real Estate Agents Disciplinary Tribunal 2010", "id": "NZREADT", states: ['New Zealand'] },
        { "name": "New Zealand Sports Tribunal 2003", "id": "NZST", states: ['New Zealand'] },
        { "name": "New Zealand Teachers Disciplinary Tribunal 2005", "id": "NZTDT", states: ['New Zealand'] },
        { "name": "New Zealand Victims' Special Claims Tribunal 2009", "id": "NZVSCT", states: ['New Zealand'] },
        { "name": "New Zealand Weathertight Homes Tribunal Decisions 2003", "id": "NZWHT", states: ['New Zealand'] },
        { "name": "New Zealand Weathertight Homes Tribunal Eligibility Decisions 2008", "id": "NZWHTE", states: ['New Zealand'] },
          // Supreme Courts
        { 'name': 'Supreme Court of New Zealand 2004', 'id': 'NZSC', states: ['New Zealand'] },
          // Court of Appeal
        { "name": "Court of Appeal of New Zealand 1888", "id": "NZCA", states: ['New Zealand'] },
        { "name": "Courts Martial Appeal Court of New Zealand 1972", "id": "NZCMAC", states: ['New Zealand'] },
        { "name": "Employment Court of New Zealand 1988", "id": "NZEmpC", states: ['New Zealand'] },
        { "name": "Labour Court of New Zealand 1987-1991", "id": "NZLabC", states: ['New Zealand'] },
        { "name": "Maori Appellate Court of New Zealand Decisions 1948", "id": "NZMAC", states: ['New Zealand'] },
        { "name": "Maori Land Court of New Zealand Decisions 1948", "id": "NZMLC", states: ['New Zealand'] },
        { "name": "New Zealand Court of Appeal Reports 1861-1877", "id": "NZCARp", states: ['New Zealand'] },
        { "name": "New Zealand Employment Relations Authority Decisions 2000", "id": "NZERA", states: ['New Zealand'] },
        { "name": "New Zealand Health and Safety in Employment Decisions 1996", "id": "NZHSE", states: ['New Zealand'] },
        { "name": "New Zealand Ministry of Business, Innovation and Employment Building Determinations 1992", "id": "NZMBIEBldg", states: ['New Zealand'] },
          // Others
        { "name": "Fenton's Important Judgments (New Zealand) 1866-1878", "id": "NZFImpJud", states: ['New Zealand'] },
        { "name": "Intellectual Property Office of New Zealand Designs 1958", "id": "NZIPODES", states: ['New Zealand'] },
        { "name": "Intellectual Property Office of New Zealand Patents 1957", "id": "NZIPOPAT", states: ['New Zealand'] },
        { "name": "Intellectual Property Office of New Zealand Plant Variety Rights 1984", "id": "NZIPOPVR", states: ['New Zealand'] },
        { "name": "Intellectual Property Office of New Zealand Trade Marks 1955", "id": "NZIPOTM", states: ['New Zealand'] },
        { "name": "Macassey's Reports New Zealand 1859-1872", "id": "NZMacasRp", states: ['New Zealand'] },
        { "name": "New Zealand Accident Compensation Appeal Authority Decisions 1978", "id": "NZACAA", states: ['New Zealand'] },
        { "name": "New Zealand Accident Compensation Appeals ACC Appeal Decisions 1993", "id": "NZACC", states: ['New Zealand'] },
        { "name": "New Zealand Accident Compensation Reports 1976-1984", "id": "NZACCRp", states: ['New Zealand'] },
        { "name": "New Zealand Advertising Standards Authority 2001", "id": "NZASA", states: ['New Zealand'] },
        { "name": "New Zealand Air Services Licensing Appeal Authority 1977-1983", "id": "NZASLAA", states: ['New Zealand'] },
        { "name": "New Zealand Alcohol Regulatory and Licensing Authority 2013", "id": "NZARLA", states: ['New Zealand'] },
        { "name": "New Zealand Appeals to the Privy Council 1851-2013", "id": "NZPC", states: ['New Zealand'] },
        { "name": "New Zealand Arbitration Commission 1988-1991", "id": "NZArbCom", states: ['New Zealand'] },
        { "name": "New Zealand Arbitration Court 1986", "id": "NZArbC", states: ['New Zealand'] },
        { "name": "New Zealand Arbitration Court Judgments 1979-1986", "id": "NZArbCJud", states: ['New Zealand'] },
        { "name": "New Zealand Book of Awards Decisions 1960", "id": "NZBkAwdDec", states: ['New Zealand'] },
        { "name": "New Zealand Broadcasting Standards Authority Decisions 1995", "id": "NZBSA", states: ['New Zealand'] },
        { "name": "New Zealand Colonial Law Journal 1865-1875", "id": "NZColLawJl", states: ['New Zealand'] },
        { "name": "New Zealand Commerce Commission Decisions 1975", "id": "NZComComm", states: ['New Zealand'] },
        { "name": "New Zealand Compensation Court 1967", "id": "NZCompC", states: ['New Zealand'] },
        { "name": "New Zealand Coroners Court 2007", "id": "NZCorC", states: ['New Zealand'] },
        { "name": "New Zealand Customs Appeal Authority Decisions 1997", "id": "NZCAA", states: ['New Zealand'] },
        { "name": "New Zealand Domain Name Commission 2006", "id": "NZDNC", states: ['New Zealand'] },
        { "name": "New Zealand Film and Literature Board of Review 1997", "id": "NZFLBR", states: ['New Zealand'] },
        { "name": "New Zealand Gazette Law Reports 1876-1953", "id": "NZGazLawRp", states: ['New Zealand'] },
        { "name": "New Zealand Human Rights Commissioner Case Notes 1988", "id": "NZHRCCN", states: ['New Zealand'] },
        { "name": "New Zealand Industrial Court Judgments 1974-1978", "id": "NZICJud", states: ['New Zealand'] },
        { "name": "New Zealand Industrial Law Reports 1987-1990", "id": "NZILawRp", states: ['New Zealand'] },
        { "name": "New Zealand Jurist Reports 1859-1879", "id": "NZJurRp", states: ['New Zealand'] },
        { "name": "New Zealand Land Valuation Cases 1961-1993", "id": "NZLVCas", states: ['New Zealand'] },
        { "name": "New Zealand Legal Aid Appeal Authority 1976-1993", "id": "NZLAAA", states: ['New Zealand'] },
        { "name": "New Zealand Legal Aid Review Authority 1992-2001", "id": "NZLARA", states: ['New Zealand'] },
        { "name": "New Zealand Legal Aid Review Panel 2001-2011", "id": "NZLARP", states: ['New Zealand'] },
        { "name": "New Zealand Legal Complaints Review Officer Decisions 2009", "id": "NZLCRO", states: ['New Zealand'] },
        { "name": "New Zealand Licensing Authority of Secondhand Dealers and Pawnbrokers Decisions 2005", "id": "NZSHD", states: ['New Zealand'] },
        { "name": "New Zealand Licensing Control Commission 1970-1990", "id": "NZLCC", states: ['New Zealand'] },
        { "name": "New Zealand Liquor Licensing Authority Decisions 1990-2012", "id": "NZLLA", states: ['New Zealand'] },
        { "name": "New Zealand Lost Cases 1842-1882", "id": "NZLostC", states: ['New Zealand'] },
        { "name": "New Zealand Magistrates Court 1976-1980", "id": "NZMC", states: ['New Zealand'] },
        { "name": "New Zealand Magistrates' Court Reports 1898-1952", "id": "NZMCRp", states: ['New Zealand'] },
        { "name": "New Zealand Matrimonial Property Cases 1977-1982", "id": "NZMPC", states: ['New Zealand'] },
        { "name": "New Zealand Media Council 2018", "id": "NZMediaC", states: ['New Zealand'] },
        { "name": "New Zealand Motor Spirits Licensing Appeal Authority 1965", "id": "NZMSLAA", states: ['New Zealand'] },
        { "name": "New Zealand Native Appellate Court 1894-1947", "id": "NZNAC", states: ['New Zealand'] },
        { "name": "New Zealand Native Land Court 1869-1945", "id": "NZNLC", states: ['New Zealand'] },
        { "name": "New Zealand Online Media Standards Authority 2013-2016", "id": "NZOMSA", states: ['New Zealand'] },
        { "name": "New Zealand Overtime and Shift Work Recognition Authority 1975-1982", "id": "NZOSWRA", states: ['New Zealand'] },
        { "name": "New Zealand Pharmacy Authority 1978-1997", "id": "NZPhA", states: ['New Zealand'] },
        { "name": "New Zealand Plumbers Gasfitters and Drainlayers Board 2018", "id": "NZPGDB", states: ['New Zealand'] },
        { "name": "New Zealand Police Law Reports 1910-1968", "id": "NZPoliceLawRp", states: ['New Zealand'] },
        { "name": "New Zealand Press Council 1994-2018", "id": "NZPressC", states: ['New Zealand'] },
        { "name": "New Zealand Privacy Commissioner Cases 1994", "id": "NZPrivCmr", states: ['New Zealand'] },
        { "name": "New Zealand Private Security Personnel Licensing Authority 2019", "id": "NZPSPLA", states: ['New Zealand'] },
        { "name": "New Zealand Real Estate Agents Authority 2010", "id": "NZREAA", states: ['New Zealand'] },
        { "name": "New Zealand Real Estate Agents Licensing Board 1984-2009", "id": "NZREALB", states: ['New Zealand'] },
        { "name": "New Zealand Refugee Status Appeals Authority 1991-2010", "id": "NZRSAA", states: ['New Zealand'] },
        { "name": "New Zealand Removal Review Authority 1991-2010", "id": "NZRRA", states: ['New Zealand'] },
        { "name": "New Zealand Residence Review Board 2006-2010", "id": "NZRRB", states: ['New Zealand'] },
        { "name": "New Zealand Review Authority 2011", "id": "NZRA", states: ['New Zealand'] },
        { "name": "New Zealand Taxation Review Authority Decisions 1984", "id": "NZTRA", states: ['New Zealand'] },
        { "name": "New Zealand Town and Country Planning Appeal Board 1955-1983", "id": "NZTCPAB", states: ['New Zealand'] },
        { "name": "New Zealand Trade Practices Appeal Authority 1959-1965", "id": "NZTPAA", states: ['New Zealand'] },
        { "name": "New Zealand Trade Practices and Prices Commission 1959-1967", "id": "NZTPPCom", states: ['New Zealand'] },
        { "name": "New Zealand Transport Charges Appeal Authority 1969-1981", "id": "NZTCAA", states: ['New Zealand'] },
        { "name": "New Zealand Transport Licensing Appeal Authority 1968-1981", "id": "NZTLAA", states: ['New Zealand'] },
        { "name": "New Zealand Workers Compensation Cases 1901-1940", "id": "NZWkrCompC", states: ['New Zealand'] },
        { "name": "Ollivier, Bell, & Fitzgerald's Reports New Zealand 1878-1880", "id": "NZOBFitzRp", states: ['New Zealand'] },
      ]
    },
  },

  'SG': {
    states: [
      "Singapore|()",
    ],
    hierarchy: {
      "Court of Appeal": "(Court of Appeal)",
      "High Court": "(High Court)",
      "State Court": "(District Court)|(Magistrates' Court)",
    },
    courts: {
      'Singapore Jurisdiction': [
        { 'name': 'Court of Appeal 2005', 'id': 'SGCA', states: ['Singapore'] },
        { 'name': 'High Court 2005', 'id': 'SGHC', states: ['Singapore'] },
        { 'name': 'District Court of Singapore 2006', 'id': 'SGDC', states: ['Singapore'] },
        { 'name': "Magistrates' Court of Singapore 2006", 'id': 'SGMC', states: ['Singapore'] },
        { 'name': 'Intellectual Property Office of Singapore 1999', 'id': 'SGIPOS', states: ['Singapore'] },
      ],
    },
  },

  'MY': {
    states: [
      "Malaysia"
    ],
    hierarchy: {
      "Supreme Court": "(Federal Court)",
      "Intermediate Courts": "(Court of Appeal)|(High Courts)",
      "Subordinate Courts": "(Magistrates Courts)|(Sessions Courts)",
    },
    courts: {
      'Malaysia Jurisdiction': [
        { 'name': 'Federal Court of Malaysia', 'id': 1, states: ['Malaysia'] },
        { 'name': 'Court of Appeal of Malaysia', 'id': 2, states: ['Malaysia'] },
        { 'name': 'High Courts of Malaysia', 'id': 3, states: ['Malaysia'] },
        { 'name': 'Magistrates Courts of Malaysia', 'id': 5, states: ['Malaysia'] },
        { 'name': 'Sessions Courts of Malaysia', 'id': 4, states: ['Malaysia'] },
      ]
    },
  },
}